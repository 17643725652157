import * as t from '../types';
import Axios from 'lib/utils/axios';

export const getDashboardSummaryAction = params => async dispatch => {
  try {
    dispatch({ type: t.GET_DASHBOARD_SUMMARY_REQUEST });
    const { data } = await Axios.get('/dashboard/store-summary', {
      params,
    }).then(({ data }) => data);
    dispatch({ type: t.GET_DASHBOARD_SUMMARY_SUCCESS, payload: data });
  } catch (e) {
    dispatch({ type: t.GET_DASHBOARD_SUMMARY_FAILURE });
  }
};
