import React from 'react';
import PropTypes from 'prop-types';

function SvgChevronRight({ fill, ...props }) {
  return (
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.169215 9.82793C0.280336 9.93722 0.429656 9.99894 0.585516 10C0.741375 9.99894 0.890696 9.93722 1.00182 9.82793L5.42571 5.40542C5.53553 5.2947 5.59716 5.14507 5.59716 4.98912C5.59716 4.83317 5.53553 4.68354 5.42571 4.57282L1.0032 0.148928C0.890777 0.0490137 0.744424 -0.00417008 0.594081 0.000255614C0.443737 0.00468131 0.300766 0.066382 0.194411 0.172737C0.0880561 0.279092 0.0263554 0.422063 0.0219297 0.572407C0.017504 0.72275 0.0706878 0.869104 0.170602 0.98153L4.17542 4.98912L0.169215 8.99533C0.0607152 9.10674 0 9.25611 0 9.41163C0 9.56714 0.0607152 9.71651 0.169215 9.82793Z"
        fill={fill}
      />
    </svg>
  );
}

SvgChevronRight.propTypes = {
  fill: PropTypes.string,
};

SvgChevronRight.defaultProps = {
  fill: '#AAAAAA',
};

export default SvgChevronRight;
