import firebase from 'services/firebase';
import axios from 'axios';
import { logoutAction } from './auth';
import * as t from '../types';

export const getInstagramImagesAction = () => async (dispatch, getState) => {
  const { instagramId, id } = getState().user.profile;
  dispatch({ type: t.GET_INSTAGRAM_PICTURES_REQUEST });

  try {
    const doc = await firebase
      .firestore()
      .collection('instagramAccessToken')
      .doc(id)
      .get();
    const { accessToken } = doc.data();
    if (doc.exists) {
      const { data } = await axios.get(
        `https://graph.instagram.com/${instagramId}/media?fields=id,media_type,media_url,caption&access_token=${accessToken}`,
      );
      dispatch({ type: t.GET_INSTAGRAM_PICTURES_SUCCESS, payload: data });
    } else {
      dispatch(logoutAction());
    }
  } catch (e) {
    dispatch({ type: t.GET_INSTAGRAM_PICTURES_FAILURE });
  }
};

export const getMoreInstagramImagesAction = () => async (dispatch, getState) => {
  const { paging, data } = getState().image;

  dispatch({ type: t.GET_MORE_INSTAGRAM_PICTURES_REQUEST });
  try {
    if (!paging.next) throw new Error({ message: 'No new pages' });
    const resp = await axios.get(paging.next);
    if (resp.status === 200) {
      const { data: respData } = resp;
      dispatch({
        type: t.GET_MORE_INSTAGRAM_PICTURES_SUCCESS,
        payload: { paging: respData.paging, data: [...data, ...respData.data] },
      });
    } else {
      throw resp.data;
    }
  } catch (e) {
    dispatch({ type: t.GET_MORE_INSTAGRAM_PICTURES_FAILURE });
  }
};
