import { combineReducers } from 'redux';
import analytics from './analytics';
import auth from './auth';
import image from './image';
import order from './order';
import product from './product';
import store from './store';
import user from './user';
import visit from './visit';
import cart from './cart';
import chat from './chat';
import checkout from './checkout';
import dashboard from './dashboard';

const appReducer = combineReducers({
  analytics,
  auth,
  image,
  order,
  product,
  store,
  user,
  visit,
  cart,
  chat,
  checkout,
  dashboard,
});

export default appReducer;
