import { getCountry, getStates } from 'country-state-picker';
export const countries = [
  { name: 'Afghanistan', code: '93', abbv: 'af' },
  { name: 'Albania', code: '355', abbv: 'al' },
  { name: 'Algeria', code: '213', abbv: 'dz' },
  { name: 'Andorra', code: '376', abbv: 'ad' },
  { name: 'Angola', code: '244', abbv: 'ao' },
  { name: 'Antigua and Barbuda', code: '1268', abbv: 'ag' },
  { name: 'Argentina', code: '54', abbv: 'ar' },
  { name: 'Armenia', code: '374', abbv: 'am' },
  { name: 'Aruba', code: '297', abbv: 'aw' },
  { name: 'Australia', code: '61', abbv: 'au' },
  { name: 'Austria', code: '43', abbv: 'at' },
  { name: 'Azerbaijan', code: '994', abbv: 'az' },
  { name: 'Bahamas', code: '1242', abbv: 'bs' },
  { name: 'Bahrain', code: '973', abbv: 'bh' },
  { name: 'Bangladesh', code: '880', abbv: 'bd' },
  { name: 'Barbados', code: '1246', abbv: 'bb' },
  { name: 'Belarus', code: '375', abbv: 'by' },
  { name: 'Belgium', code: '32', abbv: 'be' },
  { name: 'Belize', code: '501', abbv: 'bz' },
  { name: 'Benin', code: '229', abbv: 'bj' },
  { name: 'Bhutan', code: '975', abbv: 'bt' },
  { name: 'Bolivia', code: '591', abbv: 'bo' },
  { name: 'Bosnia and Herzegovina', code: '387', abbv: 'ba' },
  { name: 'Botswana', code: '267', abbv: 'bw' },
  { name: 'Brazil', code: '55', abbv: 'br' },
  { name: 'British Indian Ocean Territory', code: '246', abbv: 'io' },
  { name: 'Brunei', code: '673', abbv: 'bn' },
  { name: 'Bulgaria', code: '359', abbv: 'bg' },
  { name: 'Burkina Faso', code: '226', abbv: 'bf' },
  { name: 'Burundi', code: '257', abbv: 'bi' },
  { name: 'Cambodia', code: '855', abbv: 'kh' },
  { name: 'Cameroon', code: '237', abbv: 'cm' },
  { name: 'Canada', code: '1', abbv: 'ca' },
  { name: 'Cape Verde', code: '238', abbv: 'cv' },
  { name: 'Caribbean Netherlands', code: '599', abbv: 'bq' },
  { name: 'Central African Republic', code: '236', abbv: 'cf' },
  { name: 'Chad', code: '235', abbv: 'td' },
  { name: 'Chile', code: '56', abbv: 'cl' },
  { name: 'China', code: '86', abbv: 'cn' },
  { name: 'Colombia', code: '57', abbv: 'co' },
  { name: 'Comoros', code: '269', abbv: 'km' },
  { name: 'Congo', code: '243', abbv: 'cd' },
  { name: 'Congo', code: '242', abbv: 'cg' },
  { name: 'Costa Rica', code: '506', abbv: 'cr' },
  { name: 'Côte d’Ivoire', code: '225', abbv: 'ci' },
  { name: 'Croatia', code: '385', abbv: 'hr' },
  { name: 'Cuba', code: '53', abbv: 'cu' },
  { name: 'Curaçao', code: '599', abbv: 'cw' },
  { name: 'Cyprus', code: '357', abbv: 'cy' },
  { name: 'Czech Republic', code: '420', abbv: 'cz' },
  { name: 'Denmark', code: '45', abbv: 'dk' },
  { name: 'Djibouti', code: '253', abbv: 'dj' },
  { name: 'Dominica', code: '1767', abbv: 'dm' },
  { name: 'Dominican Republic', code: '1', abbv: 'do' },
  { name: 'Ecuador', code: '593', abbv: 'ec' },
  { name: 'Egypt', code: '20', abbv: 'eg' },
  { name: 'El Salvador', code: '503', abbv: 'sv' },
  { name: 'Equatorial Guinea', code: '240', abbv: 'gq' },
  { name: 'Eritrea', code: '291', abbv: 'er' },
  { name: 'Estonia', code: '372', abbv: 'ee' },
  { name: 'Ethiopia', code: '251', abbv: 'et' },
  { name: 'Fiji', code: '679', abbv: 'fj' },
  { name: 'Finland', code: '358', abbv: 'fi' },
  { name: 'France', code: '33', abbv: 'fr' },
  { name: 'French Guiana', code: '594', abbv: 'gf' },
  { name: 'French Polynesia', code: '689', abbv: 'pf' },
  { name: 'Gabon', code: '241', abbv: 'ga' },
  { name: 'Gambia', code: '220', abbv: 'gm' },
  { name: 'Georgia', code: '995', abbv: 'ge' },
  { name: 'Germany', code: '49', abbv: 'de' },
  { name: 'Ghana', code: '233', abbv: 'gh' },
  { name: 'Greece', code: '30', abbv: 'gr' },
  { name: 'Grenada', code: '1473', abbv: 'gd' },
  { name: 'Guadeloupe', code: '590', abbv: 'gp' },
  { name: 'Guam', code: '1671', abbv: 'gu' },
  { name: 'Guatemala', code: '502', abbv: 'gt' },
  { name: 'Guinea', code: '224', abbv: 'gn' },
  { name: 'Guinea-Bissau', code: '245', abbv: 'gw' },
  { name: 'Guyana', code: '592', abbv: 'gy' },
  { name: 'Haiti', code: '509', abbv: 'ht' },
  { name: 'Honduras', code: '504', abbv: 'hn' },
  { name: 'Hong Kong', code: '852', abbv: 'hk' },
  { name: 'Hungary', code: '36', abbv: 'hu' },
  { name: 'Iceland', code: '354', abbv: 'is' },
  { name: 'India', code: '91', abbv: 'in' },
  { name: 'Indonesia', code: '62', abbv: 'id' },
  { name: 'Iran', code: '98', abbv: 'ir' },
  { name: 'Iraq', code: '964', abbv: 'iq' },
  { name: 'Ireland', code: '353', abbv: 'ie' },
  { name: 'Israel', code: '972', abbv: 'il' },
  { name: 'Italy', code: '39', abbv: 'it' },
  { name: 'Jamaica', code: '1876', abbv: 'jm' },
  { name: 'Japan', code: '81', abbv: 'jp' },
  { name: 'Jordan', code: '962', abbv: 'jo' },
  { name: 'Kazakhstan', code: '7', abbv: 'kz' },
  { name: 'Kenya', code: '254', abbv: 'ke' },
  { name: 'Kiribati', code: '686', abbv: 'ki' },
  { name: 'Kosovo', code: '383', abbv: 'xk' },
  { name: 'Kuwait', code: '965', abbv: 'kw' },
  { name: 'Kyrgyzstan', code: '996', abbv: 'kg' },
  { name: 'Laos', code: '856', abbv: 'la' },
  { name: 'Latvia', code: '371', abbv: 'lv' },
  { name: 'Lebanon', code: '961', abbv: 'lb' },
  { name: 'Lesotho', code: '266', abbv: 'ls' },
  { name: 'Liberia', code: '231', abbv: 'lr' },
  { name: 'Libya', code: '218', abbv: 'ly' },
  { name: 'Liechtenstein', code: '423', abbv: 'li' },
  { name: 'Lithuania', code: '370', abbv: 'lt' },
  { name: 'Luxembourg', code: '352', abbv: 'lu' },
  { name: 'Macau', code: '853', abbv: 'mo' },
  { name: 'Macedonia', code: '389', abbv: 'mk' },
  { name: 'Madagascar', code: '261', abbv: 'mg' },
  { name: 'Malawi', code: '265', abbv: 'mw' },
  { name: 'Malaysia', code: '60', abbv: 'my' },
  { name: 'Maldives', code: '960', abbv: 'mv' },
  { name: 'Mali', code: '223', abbv: 'ml' },
  { name: 'Malta', code: '356', abbv: 'mt' },
  { name: 'Marshall Islands', code: '692', abbv: 'mh' },
  { name: 'Martinique', code: '596', abbv: 'mq' },
  { name: 'Mauritania', code: '222', abbv: 'mr' },
  { name: 'Mauritius', code: '230', abbv: 'mu' },
  { name: 'Mexico', code: '52', abbv: 'mx' },
  { name: 'Micronesia', code: '691', abbv: 'fm' },
  { name: 'Moldova', code: '373', abbv: 'md' },
  { name: 'Monaco', code: '377', abbv: 'mc' },
  { name: 'Mongolia', code: '976', abbv: 'mn' },
  { name: 'Montenegro', code: '382', abbv: 'me' },
  { name: 'Morocco', code: '212', abbv: 'ma' },
  { name: 'Mozambique', code: '258', abbv: 'mz' },
  { name: 'Myanmar', code: '95', abbv: 'mm' },
  { name: 'Namibia', code: '264', abbv: 'na' },
  { name: 'Nauru', code: '674', abbv: 'nr' },
  { name: 'Nepal', code: '977', abbv: 'np' },
  { name: 'Netherlands', code: '31', abbv: 'nl' },
  { name: 'New Caledonia', code: '687', abbv: 'nc' },
  { name: 'New Zealand', code: '64', abbv: 'nz' },
  { name: 'Nicaragua', code: '505', abbv: 'ni' },
  { name: 'Niger', code: '227', abbv: 'ne' },
  { name: 'Nigeria', code: '234', abbv: 'ng' },
  { name: 'North Korea', code: '850', abbv: 'kp' },
  { name: 'Norway', code: '47', abbv: 'no' },
  { name: 'Oman', code: '968', abbv: 'om' },
  { name: 'Pakistan', code: '92', abbv: 'pk' },
  { name: 'Palau', code: '680', abbv: 'pw' },
  { name: 'Palestine', code: '970', abbv: 'ps' },
  { name: 'Panama', code: '507', abbv: 'pa' },
  { name: 'Papua New Guinea', code: '675', abbv: 'pg' },
  { name: 'Paraguay', code: '595', abbv: 'py' },
  { name: 'Peru', code: '51', abbv: 'pe' },
  { name: 'Philippines', code: '63', abbv: 'ph' },
  { name: 'Poland', code: '48', abbv: 'pl' },
  { name: 'Portugal', code: '351', abbv: 'pt' },
  { name: 'Puerto Rico', code: '1', abbv: 'pr' },
  { name: 'Qatar', code: '974', abbv: 'qa' },
  { name: 'Réunion', code: '262', abbv: 're' },
  { name: 'Romania', code: '40', abbv: 'ro' },
  { name: 'Russia', code: '7', abbv: 'ru' },
  { name: 'Rwanda', code: '250', abbv: 'rw' },
  { name: 'Saint Kitts and Nevis', code: '1869', abbv: 'kn' },
  { name: 'Saint Lucia', code: '1758', abbv: 'lc' },
  { name: 'Saint Vincent and the Grenadines', code: '1784', abbv: 'vc' },
  { name: 'Samoa', code: '685', abbv: 'ws' },
  { name: 'San Marino', code: '378', abbv: 'sm' },
  { name: 'São Tomé and Príncipe', code: '239', abbv: 'st' },
  { name: 'Saudi Arabia', code: '966', abbv: 'sa' },
  { name: 'Senegal', code: '221', abbv: 'sn' },
  { name: 'Serbia', code: '381', abbv: 'rs' },
  { name: 'Seychelles', code: '248', abbv: 'sc' },
  { name: 'Sierra Leone', code: '232', abbv: 'sl' },
  { name: 'Singapore', code: '65', abbv: 'sg' },
  { name: 'Slovakia', code: '421', abbv: 'sk' },
  { name: 'Slovenia', code: '386', abbv: 'si' },
  { name: 'Solomon Islands', code: '677', abbv: 'sb' },
  { name: 'Somalia', code: '252', abbv: 'so' },
  { name: 'South Africa', code: '27', abbv: 'za' },
  { name: 'South Korea', code: '82', abbv: 'kr' },
  { name: 'South Sudan', code: '211', abbv: 'ss' },
  { name: 'Spain', code: '34', abbv: 'es' },
  { name: 'Sri Lanka', code: '94', abbv: 'lk' },
  { name: 'Sudan', code: '249', abbv: 'sd' },
  { name: 'Suriname', code: '597', abbv: 'sr' },
  { name: 'Swaziland', code: '268', abbv: 'sz' },
  { name: 'Sweden', code: '46', abbv: 'se' },
  { name: 'Switzerland', code: '41', abbv: 'ch' },
  { name: 'Syria', code: '963', abbv: 'sy' },
  { name: 'Taiwan', code: '886', abbv: 'tw' },
  { name: 'Tajikistan', code: '992', abbv: 'tj' },
  { name: 'Tanzania', code: '255', abbv: 'tz' },
  { name: 'Thailand', code: '66', abbv: 'th' },
  { name: 'Timor-Leste', code: '670', abbv: 'tl' },
  { name: 'Togo', code: '228', abbv: 'tg' },
  { name: 'Tonga', code: '676', abbv: 'to' },
  { name: 'Trinidad and Tobago', code: '1868', abbv: 'tt' },
  { name: 'Tunisia', code: '216', abbv: 'tn' },
  { name: 'Turkey', code: '90', abbv: 'tr' },
  { name: 'Turkmenistan', code: '993', abbv: 'tm' },
  { name: 'Tuvalu', code: '688', abbv: 'tv' },
  { name: 'Uganda', code: '256', abbv: 'ug' },
  { name: 'Ukraine', code: '380', abbv: 'ua' },
  { name: 'United Arab Emirates', code: '971', abbv: 'ae' },
  { name: 'United Kingdom', code: '44', abbv: 'gb' },
  { name: 'United States', code: '1', abbv: 'us' },
  { name: 'Uruguay', code: '598', abbv: 'uy' },
  { name: 'Uzbekistan', code: '998', abbv: 'uz' },
  { name: 'Vanuatu', code: '678', abbv: 'vu' },
  { name: 'Vatican City', code: '39', abbv: 'va' },
  { name: 'Venezuela', code: '58', abbv: 've' },
  { name: 'Vietnam', code: '84', abbv: 'vn' },
  { name: 'Yemen', code: '967', abbv: 'ye' },
  { name: 'Zambia', code: '260', abbv: 'zm' },
  { name: 'Zimbabwe', code: '263', abbv: 'zw' },
];

export const getStateByCountryName = countryName => {
  const country = getCountry(countryName);
  const states = country ? getStates(country.code) : [];
  return states;
};

export const currencyFormatter = (money, currency = 'NGN', minimumFractionDigits = 2) => {
  const formatter = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency,
    minimumFractionDigits,
    currencyDisplay: 'symbol',
  });
  return formatter.format(money);
};
