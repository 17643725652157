import { getAnalytics } from 'api/analytics';
import * as t from '../types';

export const getUserAnalyticsAction = () => async (dispatch, getState) => {
  try {
    const { id } = getState().user.store;
    dispatch({ type: t.GET_USER_ANALYTICS_REQUEST });

    await getAnalytics(id, (data, error) => {
      if (data) {
        dispatch({ type: t.GET_USER_ANALYTICS_SUCCESS, payload: data });
      } else {
        throw error;
      }
    });
  } catch (e) {
    dispatch({ type: t.GET_USER_ANALYTICS_FAILURE });
  }
};
