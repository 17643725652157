import React from 'react';
import PropTypes from 'prop-types';

function SvgMallblyDark({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 800 258" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.6594 69.1073L34.7772 84.136C44.2995 72.4608 57.1754 66.6232 73.4047 66.6232C90.7105 66.6232 102.593 73.4544 109.051 87.1169C118.491 73.4544 131.946 66.6232 149.418 66.6232C163.991 66.6232 174.838 70.8875 181.959 79.4162C189.08 87.8621 192.641 100.614 192.641 117.671V203.496H156.622V117.795C156.622 110.178 155.131 104.63 152.15 101.152C149.169 97.5915 143.911 95.8112 136.376 95.8112C125.612 95.8112 118.16 100.945 114.02 111.213L114.144 203.496H78.2487V117.92C78.2487 110.136 76.7169 104.506 73.6532 101.028C70.5895 97.5501 65.3729 95.8112 58.0034 95.8112C47.8187 95.8112 40.4492 100.034 35.895 108.48V203.496H0V69.1073H33.6594Z"
        fill="white"
      />
      <path
        d="M298.215 203.496C296.559 200.267 295.358 196.251 294.613 191.449C285.918 201.136 274.616 205.98 260.705 205.98C247.539 205.98 236.609 202.171 227.915 194.554C219.303 186.936 214.998 177.331 214.998 165.738C214.998 151.496 220.256 140.566 230.772 132.948C241.37 125.33 256.648 121.48 276.603 121.397H293.122V113.697C293.122 107.486 291.508 102.518 288.278 98.7921C285.132 95.066 280.122 93.2029 273.249 93.2029C267.205 93.2029 262.444 94.652 258.966 97.5501C255.571 100.448 253.874 104.423 253.874 109.474H217.979C217.979 101.69 220.38 94.4864 225.182 87.8621C229.985 81.2379 236.775 76.0627 245.552 72.3366C254.329 68.5277 264.183 66.6232 275.113 66.6232C291.673 66.6232 304.797 70.8047 314.485 79.1678C324.256 87.4481 329.141 99.1233 329.141 114.193V172.445C329.224 185.197 331.004 194.843 334.482 201.385V203.496H298.215ZM268.53 178.531C273.829 178.531 278.714 177.372 283.186 175.054C287.657 172.652 290.969 169.464 293.122 165.49V142.388H279.708C261.74 142.388 252.176 148.598 251.017 161.018L250.893 163.13C250.893 167.601 252.466 171.286 255.612 174.184C258.759 177.082 263.065 178.531 268.53 178.531Z"
        fill="white"
      />
      <path d="M394.597 203.496H358.578V12.7185H394.597V203.496Z" fill="white" />
      <path d="M462.164 203.496H426.145V12.7185H462.164V203.496Z" fill="white" />
      <path
        d="M609.222 137.792C609.222 158.327 604.502 174.847 595.063 187.35C585.623 199.77 572.954 205.98 557.056 205.98C540.082 205.98 526.958 199.977 517.684 187.971L516.566 203.496H495.451V12.7185H518.429V83.8876C527.703 72.378 540.496 66.6232 556.808 66.6232C573.12 66.6232 585.913 72.792 595.187 85.1296C604.544 97.4673 609.222 114.359 609.222 135.805V137.792ZM586.244 135.184C586.244 119.534 583.222 107.445 577.177 98.9163C571.133 90.3876 562.439 86.1233 551.095 86.1233C535.942 86.1233 525.053 93.1615 518.429 107.238V165.366C525.467 179.442 536.438 186.48 551.343 186.48C562.356 186.48 570.926 182.216 577.053 173.687C583.181 165.159 586.244 152.324 586.244 135.184Z"
        fill="white"
      />
      <path d="M663.251 203.496H640.273V12.7185H663.251V203.496Z" fill="white" />
      <path
        d="M744.108 169.837L775.408 69.1073H800L745.971 224.238C737.608 246.595 724.318 257.774 706.102 257.774L701.754 257.401L693.184 255.786V237.156L699.394 237.653C707.178 237.653 713.223 236.079 717.528 232.933C721.917 229.786 725.519 224.031 728.334 215.668L733.426 202.006L685.484 69.1073H710.573L744.108 169.837Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M229.034 50.8741C243.083 50.8741 254.471 39.4855 254.471 25.437C254.471 11.3886 243.083 0 229.034 0C214.986 0 203.597 11.3886 203.597 25.437C203.597 39.4855 214.986 50.8741 229.034 50.8741Z"
        fill="#FFE500"
      />
    </svg>
  );
}

SvgMallblyDark.propTypes = {
  fill: PropTypes.string,
};

SvgMallblyDark.defaultProps = {
  fill: '#000',
};

export default SvgMallblyDark;
