import React from 'react';

function SvgEditAlt(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 15 15" fill="none" {...props}>
      <path
        d="M11.167 9.71a.625.625 0 111.25 0v2.141a2.5 2.5 0 01-2.5 2.5H3.135a2.5 2.5 0 01-2.5-2.5V5.07a2.5 2.5 0 012.5-2.5h2.766a.625.625 0 110 1.25H3.135c-.69 0-1.25.56-1.25 1.25v6.782c0 .69.56 1.25 1.25 1.25h6.782c.69 0 1.25-.56 1.25-1.25v-2.14zm-7.174 1.615a.625.625 0 010-1.25h1.843a.625.625 0 110 1.25H3.993zm7.12-8.824l1.533 1.332.22-.252a1.016 1.016 0 00-1.533-1.333l-.22.253zm-.82.943L7.37 6.807l.195 1.323 1.338.01 2.923-3.363-1.533-1.333zm3.293-2.24a2.266 2.266 0 01.223 3.197L9.658 9.177a.625.625 0 01-.476.215L7.02 9.377a.625.625 0 01-.614-.534L6.09 6.705a.625.625 0 01.147-.501l4.151-4.776a2.266 2.266 0 013.197-.224z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgEditAlt;
