import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const HeadingTag = ({ type: Component, children, className, ...props }) => (
  <Component className={className} {...props}>
    {children}
  </Component>
);

export const Heading = styled(HeadingTag)`
  &:visited {
    color: ${props => props.theme.colors[props.color]};
  }
  color: ${props => props.theme.colors[props.color]};
  display: block
  font-weight: ${props => props.theme.font.weight[props.weight]};
  margin: 0;
  position: relative;
  text-align: ${props => props.theme.font.align[props.align]};
  ${props => {
    return css`
      font-size: ${props.theme.heading.mobile[props.type]};
      line-height: ${props.theme.heading.mobile[props.type]};
      ${({ theme }) => theme.mq.sm`
        font-size: ${props.theme.heading.desktop[props.type]};
        line-height: ${props.theme.heading.desktop[props.type]};
      `}
    `;
  }}

  ${props => {
    if (props.underlined) {
      return css`
        &:after {
          background-color: ${props.theme.colors.primaryRed};
          content: '';
          height: 0.5rem;
          left: 50%;
          min-width: 20rem;
          position: absolute;
          top: calc(100% + 1rem);
          transform: translateX(-50%) skewX(30deg);
        }
      `;
    }
  }}
`;

export const Paragraph = styled.p`
  &:visited {
    color: ${props => props.theme.colors[props.color]};
  }
  color: ${props => props.theme.colors[props.color]};
  display: block;
  font-size: ${props => props.theme.font.size[props.size]};
  font-weight: ${props => props.theme.font.weight[props.weight]};
  line-height: ${props => props.theme.font.size.big};
  margin: 0;
  text-align: ${props => props.theme.font.align[props.align]};
  text-decoration: ${props => props.decoration};
  padding: ${props => props.padding || 0};
`;

export const Text = styled.span`
  &:visited {
    color: ${props => props.theme.colors[props.color]};
  }
  color: ${props => props.theme.colors[props.color]};
  display: inline;
  font-size: ${props => props.theme.font.size[props.size]};
  font-weight: ${props => props.theme.font.weight[props.weight]};
  line-height: ${props => (props.caption ? props.theme.font.size.small : props.theme.font.size.normal)};
  text-align: ${props => props.theme.font.align[props.align]};
  text-decoration: ${({ decoration }) => decoration};
`;

HeadingTag.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']).isRequired,
};

Heading.defaultProps = {
  color: 'black',
  weight: 'normal',
  align: 'initial',
  type: 'h1',
};

Paragraph.defaultProps = {
  color: 'black',
  decoration: 'none',
  size: 'normal',
  weight: 'normal',
  align: 'initial',
};

Text.defaultProps = {
  align: 'initial',
  color: 'inherit',
  decoration: 'initial',
  size: 'inherit',
  weight: 'normal',
};

export default {
  Heading,
  Paragraph,
  Text,
};
