import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PrivateRoute } from 'components/molecules/Routes';
import { Loader } from 'components/atoms';
import { FingerprintProvider } from 'contexts/FingerprintContext';
// import { PushNotificationProvider } from 'contexts/PushNotificationContext';
import './assets/styles/flag.css';
import ResetPassword from 'pages/Auth/ResetPassword';

const Dashboard = lazy(() => import('pages/Dashboard'));
const Forgot = lazy(() => import('pages/Auth/Forgot'));
const InstagramCallback = lazy(() => import('pages/Auth/InstagramCallback'));
const InstagramDialog = lazy(() => import('pages/Auth/InstagramDialog'));
const SetupStore = lazy(() => import('pages/Auth/SetupStore'));
const Login = lazy(() => import('pages/Auth/Login'));
const NotFound = lazy(() => import('pages/NotFound'));
const Register = lazy(() => import('pages/Auth/Register'));
const Store = lazy(() => import('pages/Store/Routes'));

const Scroll = props => {
  useEffect(() => {
    if (props.location.pathname.includes('dashboard')) window.scrollTo(0, 0);
  }, [props.location]);

  return props.children;
};

Scroll.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const ScrollToTop = withRouter(Scroll);

const RouterComponent = () => {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        {/* <PushNotificationProvider> */}
        <ScrollToTop>
          <Switch>
            <PrivateRoute path="/" exact component={() => <Redirect to="/dashboard" />} />
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/setup-store" component={SetupStore} />
            <PrivateRoute path="/instagram-connect" component={InstagramDialog} />
            <PrivateRoute path="/instagram-callback" component={InstagramCallback} />
            <Route
              path="/s/:storeURL"
              render={props => (
                <Suspense fallback={<Loader />}>
                  <FingerprintProvider>
                    <Store {...props} />
                  </FingerprintProvider>
                </Suspense>
              )}
            />

            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/forgot-password" component={Forgot} />
            <Route path="/reset-password/:token" component={ResetPassword} />

            <Route
              path="/*"
              render={props => (
                <Suspense fallback={<Loader />}>
                  <NotFound {...props} />
                </Suspense>
              )}
            />
          </Switch>
        </ScrollToTop>
        {/* </PushNotificationProvider> */}
      </Suspense>
    </Router>
  );
};

export default RouterComponent;
