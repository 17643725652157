import styled from 'styled-components';

export const Toggle = styled.div`
  cursor: pointer;
  display: block;
  margin-right: 2rem;
  position: relative;
  padding: 2rem;
  span {
    background: white;
    display: block;
    height: 3px;
    position: relative;
    transition: 0.3s all;
    width: 2rem;
    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }

  &.active {
    span {
      &:first-child {
        top: 6px;
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:last-child {
        top: -4px;
        transform: rotate(-45deg);
      }
    }
  }
`;
