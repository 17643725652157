import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Typography from '../Typography';

const Tag = styled.span`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.5rem;
  border: solid 1px #bbbbbb;
  cursor: pointer;
  display: inline-block;
  font-size: 1.4rem;
  padding: 0.5rem 1rem;

  ${({ active, theme }) =>
    active &&
    css`
      border-color: ${theme.colors.black};
      background-color: ${theme.colors.black};
      color: ${theme.colors.white};
    `}
`;

const Wrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  ${Tag} {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

export const VariantSelector = ({ onChange, value, variant: { title, options } }) => {
  return (
    <Wrapper>
      <Typography.Paragraph size="tiny" style={{ marginBottom: '0.5rem', textTransform: 'uppercase' }}>
        {title}
      </Typography.Paragraph>
      {options.map(option => (
        <Tag onClick={() => onChange(title, option)} active={value === option}>
          {option}
        </Tag>
      ))}
    </Wrapper>
  );
};

VariantSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  variant: PropTypes.shape({
    title: PropTypes.string,
    options: PropTypes.array,
  }),
};

VariantSelector.defaultProps = {
  variant: {
    title: '',
    options: [],
  },
};
