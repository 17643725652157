import React from 'react';
import PropTypes from 'prop-types';

function SvgMessage({ fill, ...props }) {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M60 120C93.1371 120 120 93.1371 120 60C120 26.8629 93.1371 0 60 0C26.8629 0 0 26.8629 0 60C0 93.1371 26.8629 120 60 120Z"
        fill={fill[0]}
      />
      <path
        d="M120 60C120 56.8382 119.755 53.7337 119.284 50.7042L94.3226 25.7423L19.4388 87.1833L51.6839 119.428C54.402 119.805 57.1781 120 60 120C93.1371 120 120 93.1371 120 60Z"
        fill={fill[1]}
      />
      <path d="M19.4388 32.8167H100.561V87.183H19.4388V32.8167Z" fill="#FFCE00" />
      <path d="M60 32.8167H100.561V87.183H60V32.8167Z" fill="#FDBA12" />
      <path d="M19.4388 87.1833L60 53.5814L100.561 87.1833H19.4388Z" fill="#FFDE55" />
      <path d="M60 53.5814V53.5842V87.1833H100.561L60 53.5814Z" fill="#FFCE00" />
      <path d="M19.4388 32.8167L60 69.1584L100.561 32.8167H19.4388Z" fill="#FFAA00" />
      <path d="M100.561 32.8167H60V69.1584L100.561 32.8167Z" fill="#FF9500" />
      <path
        d="M82.8759 48.2365C90.696 48.2365 97.0355 41.8971 97.0355 34.077C97.0355 26.2569 90.696 19.9175 82.8759 19.9175C75.0558 19.9175 68.7164 26.2569 68.7164 34.077C68.7164 41.8971 75.0558 48.2365 82.8759 48.2365Z"
        fill={fill[2]}
      />
      <path
        d="M97.0352 34.077C97.0352 26.3599 90.8609 20.0895 83.1832 19.9252V48.2285C90.8609 48.0645 97.0352 41.794 97.0352 34.077Z"
        fill={fill[3]}
      />
      <path
        d="M81.5775 42.9124V30.4532L78.7202 33.3621L76.6352 31.174L82.0411 25.7423H85.233V42.9124H81.5775Z"
        fill={fill[4]}
      />
      <path d="M83.1834 25.7423H85.233V42.9124H83.1834V25.7423Z" fill="#E9EDF5" />
    </svg>
  );
}

SvgMessage.propTypes = {
  fill: PropTypes.array,
};

SvgMessage.defaultProps = {
  fill: ['black', '#49380A', '#FE6A16', '#F24500', 'white'],
};

export default SvgMessage;
