import React from 'react';
import PropTypes from 'prop-types';

function SvgMark({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" {...props} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9739 8.7613C16.0533 8.65897 16.0924 8.53043 16.0835 8.40061C16.0745 8.2708 16.0182 8.14898 15.9255 8.05878C15.8328 7.96859 15.7103 7.91646 15.5818 7.91252C15.4532 7.90857 15.3278 7.95309 15.2298 8.03743L5.9292 16.0434L1.01774 14.1216C0.804088 14.0388 0.618399 13.8958 0.482741 13.7094C0.347083 13.523 0.267159 13.3012 0.252467 13.0702C0.237775 12.8392 0.288932 12.6088 0.39986 12.4064C0.510788 12.2039 0.676822 12.038 0.878233 11.9282L21.2681 0.371645C21.4284 0.284306 21.609 0.242631 21.7909 0.251068C21.9727 0.259505 22.1488 0.317738 22.3005 0.419551C22.4522 0.521364 22.5737 0.662936 22.6521 0.829157C22.7304 0.995378 22.7627 1.18001 22.7455 1.36335L20.9569 20.2274C20.939 20.4209 20.8767 20.6075 20.775 20.7723C20.6733 20.9371 20.5349 21.0756 20.371 21.1769C20.207 21.2781 20.022 21.3392 19.8306 21.3553C19.6392 21.3714 19.4467 21.3422 19.2685 21.2698L13.291 18.9281L9.53857 22.4859C9.40007 22.6168 9.22669 22.7038 9.03986 22.736C8.85303 22.7683 8.66096 22.7444 8.48739 22.6674C8.31382 22.5904 8.16637 22.4636 8.06328 22.3026C7.9602 22.1417 7.90599 21.9538 7.90737 21.762V18.9679L15.9739 8.7613Z"
        fill="#9A9A9A"
      />
    </svg>
  );
}

SvgMark.propTypes = {
  fill: PropTypes.string,
};

SvgMark.defaultProps = {
  fill: '#fff',
};

export default SvgMark;
