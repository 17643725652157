import React from 'react';
import PropTypes from 'prop-types';

function SvgGoHome({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 27" fill="none" {...props}>
      <path
        d="M14.04 25.112a.891.891 0 01-.898.885H1.898A.891.891 0 011 25.112v-14.53c0-.263.118-.512.323-.68l10.602-8.697a.91.91 0 011.15 0l10.602 8.697c.38.313.432.87.115 1.246a.908.908 0 01-1.266.113L12.5 3.036l-9.703 7.96v13.232h10.345c.496 0 .898.396.898.884z"
        fill={fill}
        stroke={fill}
        strokeWidth={0.5}
      />
      <path
        d="M18.474 15.197h-2.627l1.955-1.903a.833.833 0 00.25-.59.819.819 0 00-.25-.59.856.856 0 00-.604-.244.872.872 0 00-.603.244l-3.401 3.324a.822.822 0 000 1.172l3.4 3.324a.86.86 0 001.204.004.822.822 0 00.004-1.176l-1.955-1.903h2.627c1.014 0 1.988.394 2.705 1.095a3.697 3.697 0 011.12 2.645c0 .991-.402 1.942-1.12 2.644a3.871 3.871 0 01-2.705 1.095.86.86 0 00-.601.243.821.821 0 000 1.176.86.86 0 00.6.243c1.466 0 2.872-.569 3.908-1.582A5.34 5.34 0 0024 20.598a5.34 5.34 0 00-1.619-3.819 5.592 5.592 0 00-3.907-1.582z"
        fill={fill}
        stroke={fill}
        strokeWidth={0.5}
      />
    </svg>
  );
}

SvgGoHome.propTypes = {
  fill: PropTypes.string,
};

SvgGoHome.defaultProps = {
  fill: '#000',
};

export default SvgGoHome;
