import * as t from '../types';

const INITIAL_STATE = {
  loading: false,
  getting: false,
  product: {},
  data: { products: [] },
  publicStore: {
    name: '',
    deliveryLocation: [],
    isDeliveryRestricted: null,
    restrictedDeliveryLocation: '',
    about: '',
    url: '',
    preferredCurrency: '',
    coverImage: {
      secureUrl: '',
    },
    avatar: {
      secureUrl: '',
    },
  },
  storeData: {
    paymentDetails: {
      bankDetails: [],
      paymentLinks: [],
    },
    deliveryLocation: [],
    user: {},
    name: '',
    url: '',
    products: [],
    avatar: {},
    coverImage: {},
    preferredCurrency: '',
  },
};

export default (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case t.GET_STORE_REQUEST:
      return { ...state, loading: true };

    case t.GET_STORE_SUCCESS:
      return { ...state, loading: false, data: payload };

    case t.GET_STORE_FAILURE:
      return { ...state, loading: false };

    case t.GET_STORE_PRODUCT_REQUEST:
      return { ...state, getting: true };

    case t.GET_STORE_PRODUCT_SUCCESS:
      return { ...state, getting: false, product: payload };

    case t.GET_STORE_PRODUCT_FAILURE:
      return { ...state, getting: false };

    case t.ADD_PAYMENT_METHOD_REQUEST:
      return { ...state, getting: true };

    case t.ADD_PAYMENT_METHOD_SUCCESS:
      return { ...state, loading: false, storeData: { ...state.storeData, ...payload } };

    case t.ADD_PAYMENT_METHOD_FAILURE:
      return { ...state, loading: false };

    case t.FETCH_STORE_SUCCESS:
      return { ...state, loading: false, storeData: { ...state.storeData, ...payload } };

    case t.FETCH_STORE_PUBLIC_REQUEST:
      return { ...state, loading: true };

    case t.FETCH_STORE_PUBLIC_SUCCESS:
      return { ...state, loading: false, publicStore: { ...state.publicStore, ...payload } };

    case t.FETCH_STORE_PUBLIC_FAILURE:
      return { ...state, loading: false };

    default:
      return { ...state };
  }
};
