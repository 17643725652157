import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SearchIcon } from 'components/icons';
import { InputComponent } from './Input';

const Wrapper = styled.div`
  align-items: center;
  padding: 1.5rem;
  display: flex;
  width: 100%;
  svg {
    margin-right: 1rem;
  }
`;

const SearchInput = styled(InputComponent)`
  font-size: ${({ theme }) => theme.font.size.small};
  letter-spacing: 0.42px;
`;

export const Searchbar = ({ className, style, ...props }) => {
  return (
    <Wrapper className={className} style={style}>
      <SearchIcon width="1.5rem" height="1.5rem" />
      <SearchInput type="search" placeholder="Search ..." {...props} />
    </Wrapper>
  );
};

Searchbar.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

Searchbar.defaultProps = {
  className: '',
  style: {},
};
