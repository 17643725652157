import * as t from '../types';
import Axios from 'lib/utils/axios';
import { toastr } from 'lib/toast';
import { fetchPublicProductAction } from 'store/actions/product';

export const fetchStoreAction = () => async dispatch => {
  try {
    dispatch({ type: t.GET_STORE_REQUEST });
    const response = await Axios.get('/store');
    dispatch({ type: t.FETCH_STORE_SUCCESS, payload: response.data.data });
    dispatch({ type: t.UPDATE_PROFILE, payload: response.data.data.user });
  } catch (error) {
    dispatch({ type: t.GET_STORE_FAILURE });
  }
};
export const updateStoreAction = options => async dispatch => {
  try {
    dispatch({ type: t.UPDATE_STORE_REQUEST });
    const response = await Axios.put('/store', options);
    await dispatch({ type: t.UPDATE_STORE_SUCCESS, payload: response.data.data });
    await dispatch({ type: t.UPDATE_PROFILE, payload: response.data.data.user });
    toastr('Details Updated', 'success');
    await dispatch(fetchStoreAction());
  } catch (error) {
    dispatch({ type: t.UPDATE_STORE_FAILURE });
  }
};

export const fetchPublicStoreAction = (options, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.FETCH_STORE_PUBLIC_REQUEST });
    const response = await Axios.get('/store/public', {
      params: {
        ...options,
      },
    });
    dispatch({ type: t.FETCH_STORE_PUBLIC_SUCCESS, payload: response.data.data });
    dispatch(fetchPublicProductAction({ storeId: response.data.data._id }));
    callback(true);
    return true;
  } catch (error) {
    dispatch({ type: t.FETCH_STORE_PUBLIC_FAILURE });
    if (error.response) {
      toastr(error.response.data.message, 'error');
      return callback(null, error.response.data);
    }
    toastr(error.message, 'error');
    callback(null, error);
  }
};

export const createStoreAction = (options, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.CREATE_STORE_REQUEST });
    await Axios.post('/store', {
      ...options,
    });
    dispatch({ type: t.CREATE_STORE_SUCCESS });
    return true;
  } catch (error) {
    dispatch({ type: t.CREATE_STORE_FAILURE });
    if (error.response) {
      return callback(null, error.response.data);
    }
    callback(null, error);
  }
};

export const uploadStoreImageAction = (options, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.UPDATE_STORE_IMAGE_REQUEST });
    await Axios.put('/store/cover-image', {
      ...options,
    });
    dispatch({ type: t.UPDATE_STORE_IMAGE_SUCCESS });
    dispatch(fetchStoreAction());
    toastr('update image successfuly', 'success');
  } catch (error) {
    dispatch({ type: t.UPDATE_STORE_IMAGE_FAILURE });
    if (error.response) {
      toastr(error.response.data.message, 'error');
      return callback(null, error.response.data);
    }
    toastr(error.message, 'error');
    callback(null, error);
  }
};
export const addPaymentAction = (options, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.ADD_PAYMENT_METHOD_REQUEST });
    const response = await Axios.post('/store/payment-details', options);
    dispatch({ type: t.ADD_PAYMENT_METHOD_SUCCESS, payload: response.data.data });
    return true;
  } catch (error) {
    dispatch({ type: t.ADD_PAYMENT_METHOD_FAILURE });
    if (error.response) {
      toastr(error.response.data.message, 'error');
      return callback(null, error.response.data);
    }
    toastr(error.message, 'error');
    callback(null, error);
  }
};
export const addDeliveryLocationAction = (options, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.ADD_DELIVERY_REQUEST });
    const response = await Axios.post('/store/delivery-location', options);
    dispatch({ type: t.ADD_DELIVERY_SUCCESS, payload: response.data.data });
    return true;
  } catch (error) {
    dispatch({ type: t.ADD_DELIVERY_FAILURE });
    if (error.response) {
      toastr(error.response.data.message, 'error');
      return callback(null, error.response.data);
    }
    toastr(error.message, 'error');
    callback(null, error);
  }
};

export const updateRestrictedDelivery = (options, callback = () => {}) => async dispatch => {
  try {
    await Axios.put('store/update-delivery-restriction', options);
    await dispatch(fetchStoreAction());
    return true;
  } catch (error) {
    if (error.response) {
      toastr(error.response.data.message, 'error');
      return callback(null, error.response.data);
    }
    toastr(error.message, 'error');
    callback(null, error);
  }
};
