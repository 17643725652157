import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

const bounce = keyframes`
0%,
100% {
  transform: scale(0);
  -webkit-transform: scale(0);
}
50% {
  transform: scale(1);
  -webkit-transform: scale(1);
}
`;

const Spinner = styled.div`
  height: 40px;
  position: relative;
  width: 40px;
`;

const Bouncer = styled.div`
  animation: ${bounce} 2s infinite ease-in-out;
  background-color: ${props => props.theme.colors.black};
  border-radius: 50%;
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;

  &:last-child {
    animation-delay: -1s;
  }
`;

const Wrapper = styled.div`
  align-items: center;
  background: #fff;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: ${({ fit }) => (fit ? 'absolute' : 'fixed')};
  right: 0;
  top: 0;
  z-index: 1;
`;

export const Loader = ({ fit }) => {
  return (
    <Wrapper fit={fit}>
      <Spinner>
        <Bouncer />
        <Bouncer />
      </Spinner>
    </Wrapper>
  );
};

Loader.propTypes = {
  fit: PropTypes.bool,
};

Loader.defaultProps = {
  fit: false,
};
