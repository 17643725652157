import React from 'react';
import PropTypes from 'prop-types';

function SvgDeliveryLocation({ fill, ...props }) {
  return (
    <svg width="58" height="50" viewBox="0 0 58 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.31581 11.8418C0.589138 11.8419 7.26078e-05 12.431 0 13.1576V44.7357C7.26078e-05 45.4624 0.589138 46.0515 1.31581 46.0515H8.06704C8.65755 48.312 10.7191 50.0002 13.1555 50.0002C15.592 50.0002 17.6548 48.3121 18.2453 46.0515H41.6202C42.2106 48.3121 44.2735 50.0002 46.7099 50.0002C49.1464 50.0002 51.2092 48.3121 51.7997 46.0515H56.5785C57.3052 46.0515 57.8942 45.4624 57.8943 44.7357V28.946C57.8938 28.7421 57.8458 28.541 57.7542 28.3588L53.8107 20.4614C53.586 20.0159 53.1287 19.7357 52.6298 19.7379H48.9291C48.2737 18.0396 47.208 16.1873 45.4519 14.6919C43.4773 13.0103 40.6398 11.8418 36.8414 11.8418H29.6044V14.4734H35.5256V39.4751H2.63162V14.4734H8.55147V11.8418H1.31581ZM38.1572 14.6777C40.61 14.9179 42.4791 15.6214 43.7429 16.6977C44.7788 17.5798 45.519 18.6519 46.0533 19.7379H38.1572V14.6777ZM38.1572 22.3709H40.7901V31.5789H38.1572V22.3709ZM43.4217 22.3709H51.819L55.2627 29.257V31.5789H43.4217V22.3709ZM38.1572 34.2105H55.2627V43.4187H51.7997C51.209 41.1595 49.1462 39.4751 46.7099 39.4751C44.2737 39.4751 42.2108 41.1595 41.6202 43.4187H38.1572V34.2105ZM2.63162 42.108H8.60673C8.36986 42.5142 8.18854 42.954 8.06704 43.4187H2.63162V42.108ZM13.1555 42.108C14.6245 42.108 15.7884 43.2667 15.7884 44.7357C15.7884 46.2047 14.6245 47.3674 13.1555 47.3674C11.6865 47.3674 10.5239 46.2047 10.5239 44.7357C10.5239 43.2667 11.6865 42.108 13.1555 42.108ZM17.7043 42.108H35.5256V43.4187H18.2453C18.1238 42.954 17.9412 42.5142 17.7043 42.108ZM46.7099 42.108C48.1789 42.108 49.3428 43.2667 49.3428 44.7357C49.3428 46.2047 48.1789 47.3674 46.7099 47.3674C45.241 47.3674 44.077 46.2047 44.077 44.7357C44.077 43.2667 45.241 42.108 46.7099 42.108Z"
        fill={fill}
      />
      <path
        d="M19.4049 0C16.3296 0 13.8153 2.5156 13.8153 5.59091V7.89232H13.1548C12.8614 7.89213 12.5763 7.99 12.3449 8.17043C12.1134 8.35087 11.949 8.60351 11.8776 8.88815L7.93401 24.6817C7.88616 24.8752 7.88287 25.0769 7.92437 25.2718C7.96587 25.4667 8.05109 25.6496 8.17359 25.8067C8.29609 25.9639 8.45268 26.0912 8.63154 26.179C8.81041 26.2668 9.00688 26.3128 9.20613 26.3136H28.9433C29.1431 26.3138 29.3403 26.2684 29.5199 26.1811C29.6996 26.0937 29.857 25.9666 29.9802 25.8093C30.1035 25.652 30.1893 25.4687 30.2311 25.2733C30.273 25.078 30.2698 24.8757 30.2218 24.6817L26.2731 8.88815C26.2019 8.60436 26.0382 8.35238 25.8078 8.17203C25.5775 7.99168 25.2935 7.89328 25.001 7.89232V5.59091C25.001 2.5156 22.4802 0 19.4049 0ZM19.4049 2.63295C21.0679 2.63295 22.3681 3.92797 22.3681 5.59091V7.89232H16.4482V5.59091C16.4482 3.92797 17.742 2.63295 19.4049 2.63295ZM14.1854 10.5252H23.4436L21.2502 23.6808H10.892L14.1854 10.5252ZM25.2567 15.665L27.2638 23.6808H23.9203L25.2567 15.665Z"
        fill={fill}
      />
    </svg>
  );
}

SvgDeliveryLocation.propTypes = {
  fill: PropTypes.string,
};

SvgDeliveryLocation.defaultProps = {
  fill: '#CCCCCC',
};

export default SvgDeliveryLocation;
