import React from 'react';
import styled from 'styled-components';
import Typography from './Typography';

const TransparentButtonTemplate = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 5px;
`;

export const TransparentButton = ({ children, onClick, color, weight, size, ...props }) => {
  return (
    <TransparentButtonTemplate onClick={onClick} {...props}>
      <Typography.Text color={color} weight={weight} size={size}>
        {children}
      </Typography.Text>
    </TransparentButtonTemplate>
  );
};
