import React from 'react';
import PropTypes from 'prop-types';

function SvgTwitter({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 26" fill="none" {...props}>
      <path
        d="M29.704 3.596a.68.68 0 00-.791.016c-.161.12-.401.227-.681.32.922-1.144.936-1.909.922-2.097a.681.681 0 00-1.073-.503 5.967 5.967 0 01-3.25 1.121A6.398 6.398 0 0020.446.735a6.452 6.452 0 00-6.4 7.212C8.117 7.799 3.307 2.209 3.26 2.152a.682.682 0 00-1.106.097c-1.51 2.58-.893 5.025.141 6.735a.678.678 0 00-1.002.587c-.044 2.71 1.194 4.336 2.443 5.282a.68.68 0 00-.392.83c.869 2.8 2.908 3.85 4.34 4.243-1.642 1.157-3.672 1.429-5.214 1.429-.986 0-1.664-.117-1.67-.119a.68.68 0 00-.527 1.213c3.095 2.328 6.833 2.816 9.424 2.816 2.047 0 3.47-.299 3.542-.314 13.3-3.106 13.805-15.031 13.784-17.204 2.493-2.29 2.89-3.185 2.953-3.408a.68.68 0 00-.27-.743zm-3.826 3.355a.68.68 0 00-.221.551c.008.13.735 12.981-12.715 16.122-.013.003-1.353.282-3.247.282-1.765 0-4.101-.241-6.33-1.22 2.04-.13 4.543-.756 6.344-2.748a.68.68 0 00-.509-1.136c-.126 0-2.802-.032-4.142-2.52.62.02 1.183-.071 1.6-.261a.68.68 0 00-.141-1.284c-.145-.032-3.226-.733-3.781-3.982.482.184 1.07.312 1.638.228a.68.68 0 00.342-1.19c-.136-.116-3.074-2.669-1.798-6.01C4.629 5.52 9.286 9.61 14.901 9.293a.68.68 0 00.62-.848 5.09 5.09 0 014.927-6.35c1.374 0 2.66.54 3.625 1.519.125.126.295.2.473.203h.105c.5 0 1.283-.06 2.181-.35a9.95 9.95 0 01-1.146 1.021.68.68 0 00.469 1.216c.11-.009.594-.051 1.193-.159-.377.387-.858.851-1.47 1.406z"
        fill="#000"
      />
    </svg>
  );
}

SvgTwitter.propTypes = {
  fill: PropTypes.string,
};

SvgTwitter.defaultProps = {
  fill: '#000',
};

export default SvgTwitter;
