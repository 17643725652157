import * as api from 'api/user/auth';
import Axios from '../../lib/utils/axios';
import config from '../../lib/config/config';
import * as t from '../types';

export const loginUser = ({ email, password }, callback = () => {}) => async dispatch => {
  dispatch({ type: t.LOGIN_REQUEST });
  try {
    const response = await Axios.post(`/auth/login`, {
      email,
      password,
    });

    localStorage.setItem('mallblyToken', JSON.stringify(response.data.data));
    dispatch({ type: t.LOGIN_SUCCESS, payload: response.data.data });
    callback(true);
  } catch (error) {
    dispatch({ type: t.LOGIN_FAILURE });
    if (error.response) {
      return callback(null, error.response.data);
    }
    callback(null, error);
  }
};

export const registerUser = ({ email, password }, callback = () => {}) => async dispatch => {
  dispatch({ type: t.REGISTER_REQUEST });
  try {
    const response = await Axios.post(`/auth/register`, {
      email,
      password,
    });
    const { data: payload } = response.data;
    localStorage.setItem('mallblyToken', JSON.stringify(payload));
    dispatch({ type: t.REGISTER_SUCCESS, payload });
    callback(true, null);
  } catch (error) {
    dispatch({ type: t.REGISTER_FAILURE });
    if (error.response) {
      return callback(null, error.response.data);
    }
    callback(null, error);
  }
};

export const forgotPasswordAction = ({ email }, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.FORGOT_PASSWORD_REQUEST });
    await Axios.post(`/auth/forgot-password`, {
      userData: {
        email,
      },
      callBackUrl: `${config.frontendBaseUrl}/reset-password`,
    });
    dispatch({ type: t.FORGOT_PASSWORD_SUCCESS });
    callback(true);
  } catch (error) {
    dispatch({ type: t.FORGOT_PASSWORD_FAILURE });
    if (error.response) {
      return callback(null, error.response.data);
    }
    callback(null, error);
  }
};

export const resetPasswordAction = ({ newPassword, resetToken }, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.RESET_PASSWORD_REQUEST });
    await Axios.post(`/auth/reset-password`, {
      resetToken,
      newPassword,
    });
    dispatch({ type: t.RESET_PASSWORD_SUCCESS });
    localStorage.removeItem('mallblyToken');
    callback(true);
  } catch (error) {
    dispatch({ type: t.RESET_PASSWORD_FAILURE });
    if (error.response) {
      return callback(null, error.response.data);
    }
    callback(null, error);
  }
};

export const logoutAction = () => dispatch => {
  api.logout();
  localStorage.removeItem('mallblyToken');
  dispatch({ type: t.LOGOUT });
};
