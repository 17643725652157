import React from 'react';

function SvgSelected(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        d="M15 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15z"
        fill="#FFE500"
      />
      <path
        d="M11.167 21.767l7.713 7.713C25.268 27.776 30 21.956 30 15v-.426l-6.057-5.583-12.776 12.776z"
        fill="#DDC600"
      />
      <path
        d="M15.378 18.36c.663.662.663 1.798 0 2.46l-1.372 1.373c-.662.662-1.798.662-2.46 0l-6.01-6.057c-.662-.663-.662-1.798 0-2.46l1.372-1.373c.663-.662 1.798-.662 2.46 0l6.01 6.057z"
        fill="#000"
      />
      <path
        d="M20.63 7.902c.663-.662 1.799-.662 2.461 0l1.372 1.373c.663.662.663 1.798 0 2.46l-10.41 10.363c-.662.662-1.798.662-2.46 0l-1.372-1.372c-.663-.663-.663-1.799 0-2.46L20.63 7.901z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgSelected;
