import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import reducer from './reducers';

const persistConfig = {
  key: 'mallblyStoreCache',
  storage,
  version: 0,
  whitelist: ['cart', 'checkout', 'chat'],
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, reducer);

const getMiddleware = () => {
  let middleware = [thunk];
  if (process.env.NODE_ENV === 'development') {
    middleware.push(logger);
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export default () => {
  const store = createStore(persistedReducer, getMiddleware());
  const persistor = persistStore(store);
  return {
    store,
    persistor,
  };
};
