import * as t from '../types';
import { REHYDRATE } from 'redux-persist';

const INITIAL_STATE = {
  messages: {},
  completed: false,
};

export default (state = INITIAL_STATE, { type, store, message, payload, completed }) => {
  switch (type) {
    case t.SET_MESSAGE:
      return {
        ...state,
        messages: {
          ...state.messages,
          [store]: state.messages[store] ? [...state.messages[store], message] : [message],
        },
      };
    case t.SET_CHAT_COMPLETED:
      return {
        ...state,
        completed,
      };
    case t.REPLACE_LAST_MESSAGE:
      const messagesCopy = { ...state.messages };
      messagesCopy[store].pop();
      messagesCopy[store].push(message);
      return { ...state, messages: { ...state.messages, ...messagesCopy } };
    case t.RESET_CHAT:
      const newMessages = { ...state.messages };
      delete newMessages[store];
      return { ...state, messages: { ...newMessages } };
    case REHYDRATE:
      const persistedChat = payload?.chat || INITIAL_STATE;
      return { ...state, ...persistedChat };
    default:
      return { ...state };
  }
};
