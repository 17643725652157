import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CaretDownIcon } from 'components/icons';

const Wrapper = styled.div`
  align-items: center;
  padding: 1.5rem;
  display: flex;
  width: max-content;
  position: relative;

  svg {
    margin-right: 1rem;
  }
`;

const Label = styled.p`
  white-space: nowrap;
  font-size: 14px;
  line-height: 130%;
  color: #888888;
  margin-right: 10px;
`;

const Value = styled.p`
  white-space: nowrap;
  font-size: 14px;
  line-height: 130%;
  margin-right: 10px;
`;

const Options = styled.div`
  position: absolute;
  width: max-content;
  background: #fff;
  right: 0;
  top: 120%;
  z-index: 10;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
`;

const Option = styled.p`
  white-space: nowrap;
  font-size: 14px;
  line-height: 130%;
  padding: 10px 15px;

  &:hover {
    color: #000;
  }

  ${({ active }) => (active ? `color: #000;` : `color: #888888;`)}
`;

export const SortSelect = ({ options, value, label, onChange, ...props }) => {
  const [showOptions, setShowOptions] = useState(false);
  const sortSelectRef = useRef(null);

  const getValueText = () => {
    let text = '';
    const activeOption = options.find(item => item.value === value);
    if (activeOption) text = activeOption.text;
    return text;
  };

  useEffect(() => {
    const sortSelect = sortSelectRef.current;
    const clickHandler = ({ target }) => {
      if (sortSelect && !sortSelect.contains(target)) setShowOptions(false);
    };
    window.addEventListener('click', clickHandler);

    return () => {
      window.removeEventListener('click', clickHandler);
    };
  }, []);

  return (
    <Wrapper
      opened={showOptions}
      ref={sortSelectRef}
      onClick={() => setShowOptions(showOptions => !showOptions)}
      {...props}
    >
      {value ? <Value>{getValueText()}</Value> : <Label>{label}</Label>}
      <CaretDownIcon width="1.5rem" height="1.5rem" />
      {showOptions && options && (
        <Options>
          {options.map(({ text, value: optionValue }) => (
            <Option active={value === optionValue} onClick={() => onChange(optionValue)}>
              {text}
            </Option>
          ))}
        </Options>
      )}
    </Wrapper>
  );
};

SortSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.any,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

SortSelect.defaultProps = {
  options: [],
  onChange: () => {},
  value: '',
  label: 'Sort by',
};
