import React from 'react';
import PropTypes from 'prop-types';

function SvgCaretDown({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 12" fill="none" {...props}>
      <path
        d="M.871.338a1.2 1.2 0 00-.343.833 1.2 1.2 0 00.343.833l8.82 8.847a1.177 1.177 0 001.66 0l8.821-8.845a1.181 1.181 0 00-.844-1.962c-.3-.009-.592.097-.816.297l-7.992 8.01L2.531.338a1.188 1.188 0 00-1.66 0z"
        fill={fill}
      />
    </svg>
  );
}

SvgCaretDown.propTypes = {
  fill: PropTypes.string,
};

SvgCaretDown.defaultProps = {
  fill: 'silver',
};

export default SvgCaretDown;
