import * as t from '../types';

const INITIAL_STATE = {
  data: {},
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.GET_USER_VISITS_REQUEST:
      return { ...state, loading: true };

    case t.GET_USER_VISITS_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    case t.GET_USER_VISITS_FAILURE:
      return { ...state, loading: false };

    case t.LOGOUT:
      return { ...state, ...INITIAL_STATE };

    default:
      return { ...state };
  }
};
