import React from 'react';
import PropTypes from 'prop-types';

function SvgCancelled({ fill, ...props }) {
  return (
    <svg width="13" height="13" {...props} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.26759 4.91761L4.04985 2.69986C3.94334 2.59336 3.77066 2.59336 3.66415 2.69986L2.69991 3.6641C2.59341 3.77061 2.59341 3.94329 2.69991 4.04979L4.91766 6.26754C4.97091 6.32079 4.97091 6.40713 4.91766 6.46038L2.69991 8.67813C2.59341 8.78464 2.59341 8.95732 2.69992 9.06382L3.66415 10.0281C3.77066 10.1346 3.94334 10.1346 4.04985 10.0281L6.26759 7.81032C6.32084 7.75706 6.40718 7.75706 6.46044 7.81032L8.67818 10.0281C8.78469 10.1346 8.95737 10.1346 9.06388 10.0281L10.0281 9.06382C10.1346 8.95732 10.1346 8.78464 10.0281 8.67813L7.81037 6.46038C7.75712 6.40713 7.75712 6.32079 7.81037 6.26754L10.0281 4.04979C10.1346 3.94329 10.1346 3.77061 10.0281 3.6641L9.06388 2.69986C8.95737 2.59336 8.78469 2.59336 8.67818 2.69986L6.46044 4.91761C6.40718 4.97086 6.32084 4.97086 6.26759 4.91761Z"
        fill={fill}
      />
    </svg>
  );
}

SvgCancelled.propTypes = {
  fill: PropTypes.string,
};

SvgCancelled.defaultProps = {
  fill: 'white',
};

export default SvgCancelled;
