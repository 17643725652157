import React from 'react';

function SvgSystemError(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 100 100" fill="none" {...props}>
      <path
        d="M50 60.938a3.729 3.729 0 01-3.54-2.549l-2.274-6.827H21.875c-4.308 0-7.813-3.504-7.813-7.812V9.375c0-4.308 3.505-7.813 7.813-7.813h56.25c4.308 0 7.813 3.505 7.813 7.813V43.75c0 4.308-3.505 7.813-7.813 7.813h-22.31l-2.276 6.825A3.728 3.728 0 0150 60.938z"
        fill="#F0BC5E"
      />
      <path
        d="M89.063 64.063H10.936v-37.5h3.126v-3.125h-3.126a3.128 3.128 0 00-3.124 3.125v37.5a3.128 3.128 0 003.125 3.124h78.124a3.128 3.128 0 003.126-3.124v-37.5a3.128 3.128 0 00-3.126-3.125h-3.124v3.125h3.124v37.5z"
        fill="#000"
      />
      <path
        d="M90.625 17.188h-4.688v3.125h4.688A4.693 4.693 0 0195.313 25v46.875a4.693 4.693 0 01-4.688 4.688H9.375a4.693 4.693 0 01-4.688-4.688V25a4.693 4.693 0 014.688-4.688h4.688v-3.125H9.375c-4.308 0-7.813 3.505-7.813 7.813v46.875c0 4.308 3.505 7.813 7.813 7.813h24.942a8.919 8.919 0 01-3.587 6.25h-4.168a4.693 4.693 0 00-4.687 4.687v3.125a4.693 4.693 0 004.688 4.688h46.874a4.693 4.693 0 004.688-4.688v-3.125a4.693 4.693 0 00-4.688-4.688h-4.165a8.919 8.919 0 01-3.588-6.25h24.941c4.308 0 7.813-3.504 7.813-7.812V25c0-4.308-3.505-7.813-7.813-7.813zM75 90.624v3.125c0 .862-.7 1.563-1.563 1.563H26.563c-.863 0-1.563-.7-1.563-1.563v-3.125c0-.862.7-1.563 1.563-1.563h46.874c.863 0 1.563.7 1.563 1.563zm-10.11-4.688H35.11a12.026 12.026 0 002.332-6.25H62.56c.178 2.288.991 4.446 2.33 6.25z"
        fill="#000"
      />
      <path
        d="M85.938 70.313h3.124v3.124h-3.124v-3.124zM79.688 70.313h3.124v3.124h-3.124v-3.124zM73.438 70.313h3.124v3.124h-3.124v-3.124zM31.25 39.063h3.125v-3.126H37.5v-3.124h-3.125v-18.75h-4.053l-10.01 18.351v3.523H31.25v3.126zm-7.595-6.25l7.595-13.926v13.925h-7.595zM73.438 39.063h3.124v-3.126h3.126v-3.124h-3.126v-18.75H72.51L62.5 32.413v3.523h10.938v3.126zm-7.596-6.25l7.596-13.926v13.925h-7.596zM42.188 34.375a4.693 4.693 0 004.687 4.688h6.25a4.693 4.693 0 004.688-4.688v-.916a10.868 10.868 0 003.124-7.654V25c0-6.03-4.907-10.938-10.937-10.938-6.03 0-10.938 4.908-10.938 10.938v.805c0 2.884 1.11 5.598 3.126 7.654v.916zm0-9.375c0-4.308 3.504-7.813 7.812-7.813s7.813 3.505 7.813 7.813v.805c0 2.087-.815 4.05-2.29 5.525l-.836.836v2.209c0 .862-.7 1.563-1.562 1.563h-1.563v-3.126h-3.124v3.126h-1.563c-.862 0-1.563-.7-1.563-1.563v-2.21l-.835-.835a7.767 7.767 0 01-2.29-5.525V25z"
        fill="#000"
      />
      <path
        d="M46.875 25a1.563 1.563 0 100-3.125 1.563 1.563 0 000 3.125zM53.125 25a1.563 1.563 0 100-3.125 1.563 1.563 0 000 3.125zM20.313 7.813h3.125v3.125h-3.125V7.812zM32.813 7.813h3.124v3.125h-3.124V7.812zM26.563 7.813h3.125v3.125h-3.125V7.812zM64.063 42.188h3.124v3.124h-3.124v-3.124zM76.563 42.188h3.124v3.124h-3.124v-3.124zM70.313 42.188h3.124v3.124h-3.124v-3.124z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgSystemError;
