import * as t from '../types';
import Axios from 'lib/utils/axios';
import { toastr } from 'lib/toast';

/**
 *
 * @param {Object} product
 * Stores viewed products to localStorage
 */
const setViewedProducts = product => {
  const key = 'viewedProducts';
  const oldViewedProducts = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : [];
  const newViewedProducts = [product, ...oldViewedProducts.filter(oldProduct => oldProduct._id !== product._id)];
  localStorage.setItem(key, JSON.stringify(newViewedProducts));
  return newViewedProducts;
};

export const createProductAction = (options, onSuccess = () => {}, onError = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.CREATE_PRODUCT_REQUEST });
    await Axios.post('/product', {
      price: options.price,
      title: options.title,
      details: options.details,
      variants: options.variants,
      images: options.newImages,
      status: options.status,
      priceAvailable: options.priceAvailable,
      quantitySelector: options.quantitySelector,
    });
    dispatch({ type: t.CREATE_PRODUCT_SUCCESS });
    onSuccess();
  } catch (error) {
    dispatch({ type: t.CREATE_PRODUCT_FAILURE });
    if (error.response) {
      toastr(error.response.data.message, 'error');
      return onError(null, error.response.data);
    }
    toastr(error.message, 'error');
    onError(null, error);
  }
};

export const editProductAction = (options, onSuccess = () => {}, onError = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.EDIT_PRODUCT_REQUEST });
    await Axios.put(`/product/${options._id}`, {
      price: options.price,
      title: options.title,
      details: options.details,
      variants: options.variants,
      newImages: options.newImages,
      deletedImages: options.deletedImages,
      status: options.status,
      priceAvailable: options.priceAvailable,
      quantitySelector: options.quantitySelector,
    });
    dispatch({ type: t.EDIT_PRODUCT_SUCCESS });
    onSuccess();
  } catch (error) {
    dispatch({ type: t.EDIT_PRODUCT_FAILURE });
    if (error.response) {
      toastr(error.response.data.message, 'error');
      return onError(null, error.response.data);
    }
    toastr(error.message, 'error');
    onError(null, error);
  }
};

export const fetchProductAction = (options, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.GET_USER_PRODUCTS_REQUEST });
    const response = await Axios.get('/product');
    dispatch({ type: t.GET_USER_PRODUCTS_SUCCESS, payload: response.data.data.products });
    return true;
  } catch (error) {
    dispatch({ type: t.GET_USER_PRODUCTS_FAILURE });
    if (error.response) {
      toastr(error.response.data.message, 'error');
      return callback(null, error.response.data);
    }
    toastr(error.message, 'error');
    callback(null, error);
  }
};
export const fetchPublicProductAction = (options, callback = () => {}) => async dispatch => {
  try {
    dispatch({ type: t.FETCH_PRODUCT_PUBLIC_REQUEST });
    const response = await Axios.get('/product/public', {
      params: {
        ...options,
      },
    });
    dispatch({ type: t.FETCH_PRODUCT_PUBLIC_SUCCESS, payload: response.data.data.products });
    return true;
  } catch (error) {
    dispatch({ type: t.FETCH_PRODUCT_PUBLIC_FAILURE });
    if (error.response) {
      toastr(error.response.data.message, 'error');
      return callback(null, error.response.data);
    }
    toastr(error.message, 'error');
    callback(null, error);
  }
};
export const fetchProductDetailsAction = (options, callback = () => {}) => async dispatch => {
  const { slug, id } = options;
  const scope = slug ? 'public' : 'private';
  try {
    dispatch({ type: t.FETCH_PRODUCT_DETAILS_REQUEST });
    const response = slug ? await Axios.get(`/product/public/${slug}`) : await Axios.get(`/product/${id}`); //fetches either public product or private depending on the perspective (user, vendor)
    const viewedProducts = scope === 'public' ? setViewedProducts(response.data.data) : [];
    dispatch({
      type: t.FETCH_PRODUCT_DETAILS_SUCCESS,
      payload: { product: response.data.data, viewedProducts },
    });
    callback(response.data.data);
    return true;
  } catch (error) {
    dispatch({ type: t.FETCH_PRODUCT_DETAILS_FAILURE });
    if (error.response) {
      toastr(error.response.data.message, 'error');
      return callback(null, error.response.data);
    }
    toastr(error.message, 'error');
    callback(null, error);
  }
};
