import React from 'react';
import PropTypes from 'prop-types';

function SvgPackage({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 148 150" fill="none" {...props}>
      <path
        d="M134.206 67.245a2.64 2.64 0 00-2.36-1.456h-31.642V44.737c0-7.255-5.915-13.158-13.185-13.158h-26.37c-7.269 0-13.184 5.903-13.184 13.158v21.052H15.822c-.999 0-1.911.564-2.36 1.456L.279 93.56a2.629 2.629 0 00.116 2.56 2.636 2.636 0 002.244 1.247h10.548v39.474C13.186 144.097 19.1 150 26.37 150H121.3c7.27 0 13.184-5.903 13.184-13.158V97.368h10.548c.915 0 1.761-.473 2.244-1.25a2.623 2.623 0 00.116-2.56l-13.185-26.313zM52.739 44.737c0-4.353 3.55-7.895 7.91-7.895h26.37c4.361 0 7.91 3.542 7.91 7.895v21.052H84.315c1.674-2.205 2.705-4.92 2.705-7.894a2.634 2.634 0 00-2.637-2.632H63.287a2.634 2.634 0 00-2.637 2.632c0 2.973 1.031 5.69 2.705 7.894H52.74V44.737zm13.636 15.79h14.917a7.925 7.925 0 01-7.46 5.262 7.922 7.922 0 01-7.457-5.263zM17.452 71.052h78.485L85.389 92.105H6.905l10.547-21.052zm1.007 65.789V97.368h68.56c1 0 1.912-.563 2.36-1.455l8.188-16.345v65.169h-26.37v-28.948a2.637 2.637 0 00-3.815-2.355l-4.095 2.045-4.095-2.042a2.641 2.641 0 00-2.36 0l-4.093 2.042-4.095-2.042a2.643 2.643 0 00-2.566.116 2.628 2.628 0 00-1.25 2.236v28.948H26.37c-4.361 0-7.91-3.542-7.91-7.895zm31.643 7.895v-24.69l1.459.727a2.641 2.641 0 002.36 0l4.092-2.042 4.095 2.042a2.641 2.641 0 002.36 0l1.456-.727v24.69H50.102zm79.107-7.895c0 4.353-3.549 7.895-7.91 7.895H102.84V79.568l8.188 16.345a2.64 2.64 0 002.36 1.455h15.821v39.474zm-14.191-44.737L104.47 71.053h25.747l10.547 21.052h-25.746z"
        fill={fill}
      />
      <path
        d="M65.924 50a2.634 2.634 0 002.637-2.632 2.634 2.634 0 00-2.637-2.631 2.634 2.634 0 00-2.637 2.631A2.634 2.634 0 0065.924 50zM81.745 50a2.634 2.634 0 002.637-2.632 2.634 2.634 0 00-2.637-2.631 2.634 2.634 0 00-2.637 2.631A2.634 2.634 0 0081.745 50zM21.097 60.526h5.273c0-14.51-11.829-26.316-26.369-26.316v5.264c11.632 0 21.096 9.444 21.096 21.052zM31.644 36.842h5.274c0-18.863-15.378-34.21-34.28-34.21v5.263c15.993 0 29.006 12.987 29.006 28.947zM60.65 26.316h5.274c0-13.06-10.648-23.684-23.733-23.684v5.263c10.179 0 18.459 8.266 18.459 18.42zM121.299 60.526h5.274c0-11.608 9.464-21.052 21.095-21.052V34.21c-14.54 0-26.369 11.805-26.369 26.315zM145.031 7.895V2.632c-18.901 0-34.28 15.347-34.28 34.21h5.274c0-15.96 13.013-28.947 29.006-28.947zM105.477 7.895V2.632c-13.084 0-23.732 10.623-23.732 23.684h5.274c0-10.155 8.28-18.421 18.458-18.421zM36.918 42.105h-5.273v5.263h5.273v-5.263zM36.918 52.632h-5.273v5.263h5.273v-5.263zM116.025 42.105h-5.274v5.263h5.274v-5.263zM116.025 52.632h-5.274v5.263h5.274v-5.263zM36.918 2.632h-5.273v5.263h5.273V2.632zM116.025 2.632h-5.274v5.263h5.274V2.632zM76.472 21.053h-5.274v5.263h5.274v-5.263zM76.472 10.526h-5.274v5.263h5.274v-5.263zM76.472 0h-5.274v5.263h5.274V0zM50.102 21.053H44.83v5.263h5.273v-5.263zM102.841 21.053h-5.274v5.263h5.274v-5.263z"
        fill={fill}
      />
    </svg>
  );
}

SvgPackage.propTypes = {
  fill: PropTypes.string,
};

SvgPackage.defaultProps = {
  fill: '#A7A7A7',
};

export default SvgPackage;
