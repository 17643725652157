import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/performance';
import 'firebase/messaging';
import { config } from 'lib/config/firebase.config';

firebase.initializeApp(config);
firebase.performance();

let messaging = {};

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(`${process.env.REACT_APP_FIREBASE_CLOUD_MESSAGING_VAPID_KEY}`);
}

const firebaseAuth = firebase.auth();
const fireStore = firebase.firestore();
const storage = firebase.storage();
const database = firebase.database();
const timestamp = firebase.firestore.FieldValue.serverTimestamp();
export const { FieldValue } = firebase.firestore;

export { firebaseAuth, fireStore, storage, timestamp, database, messaging };

export default firebase;
