import React from 'react';
import PropTypes from 'prop-types';

function SvgChevronLeft({ fill, ...props }) {
  return (
    <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.34449 10.4595C1.11482 10.6757 1 10.9459 1 11.2162C1 11.4865 1.11482 11.7568 1.34449 11.973L10.5885 20.6757C11.0478 21.1081 11.7369 21.1081 12.1962 20.6757C12.6555 20.2432 12.6555 19.5946 12.1962 19.1622L3.75597 11.2162L12.6555 2.83785C13.1148 2.40542 13.1148 1.75676 12.6555 1.32433C12.2536 0.891891 11.5072 0.891891 11.0479 1.32433L1.34449 10.4595Z"
        fill={fill}
        stroke="black"
        strokeWidth="0.5"
      />
    </svg>
  );
}

SvgChevronLeft.propTypes = {
  fill: PropTypes.string,
};

SvgChevronLeft.defaultProps = {
  fill: '#000',
};

export default SvgChevronLeft;
