import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Fingerprint from 'fingerprintjs';
import Axios from 'lib/utils/axios';

export const FingerprintContext = React.createContext({});

const fingerprint = new Fingerprint({ canvas: true, screen_resolution: true }).get();

const apiActivityLogger = data => {
  try {
    Axios.post('/activity-log', data);
  } catch (error) {}
};

export const FingerprintProvider = ({ children }) => {
  const actionLogger = useCallback(logs => {
    apiActivityLogger({
      actor: fingerprint.toString(10),
      action: logs.action,
      primaryEntity: logs.primaryEntity,
      secondaryEntity: logs.secondaryEntity,
      meta: logs.meta,
    });
  }, []);

  return <FingerprintContext.Provider value={{ fingerprint, actionLogger }}>{children}</FingerprintContext.Provider>;
};

FingerprintProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
