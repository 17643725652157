import React from 'react';

function SvgIgColored(props) {
  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 35 35" {...props}>
      <rect width={35} height={35} fill="url(#ig-colored_svg__prefix__paint0_linear)" rx={17.5} />
      <path
        fill="#fff"
        d="M22.042 8h-8.084A5.965 5.965 0 008 13.958v8.084A5.965 5.965 0 0013.958 28h8.084A5.965 5.965 0 0028 22.042v-8.084A5.965 5.965 0 0022.042 8zm3.946 14.042a3.946 3.946 0 01-3.946 3.946h-8.084a3.946 3.946 0 01-3.946-3.946v-8.084a3.946 3.946 0 013.946-3.946h8.084a3.946 3.946 0 013.946 3.946v8.084z"
      />
      <path
        fill="#fff"
        d="M18 12.827A5.179 5.179 0 0012.827 18 5.179 5.179 0 0018 23.173 5.179 5.179 0 0023.173 18 5.179 5.179 0 0018 12.827zm0 8.334a3.16 3.16 0 110-6.318 3.16 3.16 0 010 6.317zm5.183-7.055a1.24 1.24 0 100-2.48 1.24 1.24 0 000 2.48z"
      />
      <defs>
        <linearGradient
          id="ig-colored_svg__prefix__paint0_linear"
          x1={17.5}
          x2={17.5}
          y1={34.898}
          y2={0.272}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E09B3D" />
          <stop offset={0.3} stopColor="#C74C4D" />
          <stop offset={0.6} stopColor="#C21975" />
          <stop offset={1} stopColor="#7024C4" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgIgColored;
