import { fireStore, timestamp } from 'services/firebase';
import { updateAnalytics } from 'api/analytics';
import { ANALYTICS } from 'lib/constants/analytics';

const inquiryCollection = fireStore.collection('inquiry');
const invoiceCollection = fireStore.collection('invoices');

export const createInquiry = async (cartItems, { name, number, address }, callback) => {
  try {
    const items = cartItems.map(item => ({ ...item }));
    const paid = cartItems.reduce((acc, cur) => {
      acc[cur.productId] = false;
      return acc;
    }, {});
    let storeId = '';

    if (items.length) {
      const [first] = items;
      ({ storeId } = first);
    }

    const { id } = await inquiryCollection.add({
      storeId,
      viewed: false,
      createdAt: timestamp,
      customer: {
        name,
        number,
        address,
      },
      items,
      paid,
    });
    await inquiryCollection.doc(id).update({ id });

    callback(true, null);
  } catch (e) {
    callback(null, e);
  }
};

export const getUserOrders = async (userId, callback) => {
  try {
    const orders = [];
    const snapshot = await inquiryCollection
      .where('storeId', '==', userId)
      .orderBy('createdAt', 'desc')
      .get();
    if (snapshot.docs && snapshot.docs.length) {
      snapshot.forEach(doc => {
        orders.push({ id: doc.id, ...doc.data() });
      });
    }
    callback(orders, null);
  } catch (e) {
    callback(false, null, e);
  }
};

export const getOrderById = async (id, callback) => {
  try {
    const doc = await inquiryCollection.doc(id).get();
    const error = {};
    if (doc.exists) {
      return callback(doc.data(), null);
    }
    error.message = 'Order does not exist';
    throw error;
  } catch (e) {
    callback(null, e);
  }
};

export const markOrderAsViewed = async (id, callback) => {
  try {
    await inquiryCollection.doc(id).update({ viewed: true });
    await getOrderById(id, callback);
  } catch (e) {
    callback(null, e);
  }
};

export const createInvoice = async (data, callback = () => {}) => {
  try {
    const { id } = await invoiceCollection.add({ ...data, createdAt: timestamp });
    await invoiceCollection.doc(id).update({ id });
    const doc = await invoiceCollection.doc(id).get();
    callback(doc.data(), null);
  } catch (e) {
    callback(null, e);
  }
};

export const markItemAsPaid = async (storeId, orderId, productId, callback) => {
  try {
    await inquiryCollection.doc(orderId).update({ [`paid.${productId}`]: true });
    await getOrderById(orderId, async (data, error) => {
      if (data) {
        const product = data.items.find(item => item.productId === productId);
        product.orderId = orderId;
        product.paid = true;
        product.customer = data.customer;
        await createInvoice(product);
        await updateAnalytics(storeId, ANALYTICS.MADE_PAYMENT);
      }
      callback(data, error);
    });
  } catch (e) {
    callback(null, e);
  }
};

export const getUserInvoices = async (userId, callback) => {
  try {
    const orders = [];
    const snapshot = await invoiceCollection
      .where('storeId', '==', userId)
      .orderBy('createdAt', 'desc')
      .get();
    if (snapshot.docs && snapshot.docs.length) {
      snapshot.forEach(doc => {
        orders.push({ id: doc.id, ...doc.data() });
      });
    }
    callback(orders, null);
  } catch (e) {
    callback(null, e);
  }
};
