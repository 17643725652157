import React from 'react';

function SvgWhatsapp(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M10 .508a9.492 9.492 0 00-7.941 14.691L.836 19.34l4.3-1.19a9.492 9.492 0 0014.355-8.152c.001-5.24-4.249-9.49-9.491-9.49z"
        fill="#25D366"
      />
      <path
        d="M15.214 12.606a.344.344 0 00-.199-.286l-2.3-1.054a.216.216 0 00-.255.054l-1.039 1.172a.284.284 0 01-.324.078 7.377 7.377 0 01-2-1.23 7.454 7.454 0 01-1.684-2.09c.145-.097.54-.39.715-.933 0-.004.004-.008.004-.008.11-.336.086-.7-.035-1.031-.207-.551-.621-1.618-.781-1.782-.028-.027-.059-.054-.059-.054a.69.69 0 00-.43-.172c-.054-.004-.113-.004-.175-.004a3.27 3.27 0 00-.457.012c-.332.046-.575.27-.727.457a3.09 3.09 0 00-.547.992c-.011.039-.023.074-.035.113a2.794 2.794 0 00.063 1.695c.18.512.441 1.121.836 1.766.668 1.098 1.394 1.777 1.886 2.227.555.511 1.242 1.14 2.332 1.664a8.091 8.091 0 002.536.738c.25.023.71.027 1.238-.172.242-.094.445-.207.61-.328.433-.309.737-.774.816-1.297v-.012c.027-.199.023-.37.011-.515z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgWhatsapp;
