import * as t from '../types';
import { REHYDRATE } from 'redux-persist';

const INITIAL_STATE = {
  visitorDetails: {},
  deliveryDetails: {},
  orderDetails: {},
  orderId: '',
};

export default (
  state = INITIAL_STATE,
  { type, visitorDetails, deliveryDetails, orderDetails, orderId, resetData, payload },
) => {
  switch (type) {
    case t.SET_VISITOR_DETAILS:
      return { ...state, visitorDetails };
    case t.SET_DELIVERY_DETAILS:
      return { ...state, deliveryDetails };
    case t.SET_ORDER_DETAILS:
      return { ...state, orderDetails };
    case t.SET_ORDER_ID:
      return { ...state, orderId };
    case t.RESET_CHECKOUT:
      if (Object.values(resetData).length) return { ...state, ...resetData };
      return { ...INITIAL_STATE };
    case REHYDRATE:
      const persistedCheckout = payload?.checkout || INITIAL_STATE;
      return { ...state, ...persistedCheckout };
    default:
      return { ...state };
  }
};
