import { fireStore } from 'services/firebase';

const analyticsCollection = fireStore.collection('analytics');

export const updateAnalytics = async (id, type) => {
  try {
    const analyticsQuery = await analyticsCollection.doc(id).get();
    const currentAnalytics = analyticsQuery.data();
    const newAnalysis = {
      ...currentAnalytics,
      [type]: (currentAnalytics[type] || 0) + 1,
    };
    await analyticsCollection.doc(id).update(newAnalysis);
  } catch (e) {
    console.log({ e });
  }
};

export const getAnalytics = async (id, callback) => {
  try {
    const analyticsQuery = await analyticsCollection.doc(id).get();
    const currentAnalytics = analyticsQuery.data();

    callback(currentAnalytics, null);
  } catch (e) {
    callback(null, e);
  }
};
