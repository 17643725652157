import styled from 'styled-components';

export const Placeholder = styled.div`
  background-color: #f2f2f2;
  background-image: url(${props => props.backgroundImage});
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 0.5rem;
  height: ${props => props.height};
  width: ${props => props.width};
`;

Placeholder.defaultProps = {
  height: '100px',
  width: '100px',
};
