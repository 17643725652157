import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const UserContext = React.createContext({});

export const UserProvider = ({ children }) => {
  const [state, setState] = useState({
    loaded: false,
    store: null,
    user: null,
  });

  const updateUser = user => {
    setState(v => ({ ...v, user }));
  };

  const updateStore = newStore => {
    setState(v => ({ ...v, store: newStore }));
  };

  return (
    <UserContext.Provider
      value={{
        ...state,
        updateUser,
        updateStore,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
