import React from 'react';
import PropTypes from 'prop-types';

function SvgStore({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 51 50" fill="none" {...props}>
      <path
        d="M4.547 0c-.628 0-1.138.508-1.138 1.136v4.547H1.136C.508 5.683 0 6.193 0 6.82v7.952c0 2.105 1.456 3.886 3.41 4.395v28.56H0V50h28.407v-2.274h-2.273V32.955h1.136V30.68H9.09v2.274h1.136v14.771H5.683V19.167a4.554 4.554 0 002.27-1.404 4.536 4.536 0 003.408 1.555 4.535 4.535 0 003.41-1.556 4.535 4.535 0 003.41 1.556 4.536 4.536 0 003.41-1.555 4.527 4.527 0 003.406 1.555 4.535 4.535 0 003.41-1.556 4.535 4.535 0 003.41 1.556 4.535 4.535 0 003.41-1.556 4.535 4.535 0 003.41 1.556 4.527 4.527 0 003.406-1.555 4.564 4.564 0 002.273 1.404v5.835h2.273v-5.834c1.953-.509 3.41-2.29 3.41-4.396V6.82c0-.628-.509-1.137-1.137-1.137H46.59V1.136C46.589.51 46.079 0 45.452 0H4.547zm1.136 2.273h38.633v3.41H5.683v-3.41zm-3.41 5.684h45.453v2.268H2.273V7.957zm0 4.542h4.542v2.273c0 1.278-.99 2.274-2.268 2.274a2.245 2.245 0 01-2.274-2.274v-2.273zm6.816 0h4.546v2.273a2.245 2.245 0 01-2.274 2.274 2.244 2.244 0 01-2.272-2.274v-2.273zm6.819 0h4.546v2.273a2.244 2.244 0 01-2.272 2.274 2.245 2.245 0 01-2.274-2.274v-2.273zm6.82 0h4.542v2.273a2.245 2.245 0 01-2.273 2.274c-1.28 0-2.269-.996-2.269-2.274v-2.273zm6.815 0h4.548v2.273a2.245 2.245 0 01-2.274 2.274 2.245 2.245 0 01-2.274-2.274v-2.273zm6.82 0h4.543v2.273c0 1.278-.99 2.274-2.268 2.274a2.245 2.245 0 01-2.274-2.274v-2.273zm6.817 0h4.546v2.273a2.245 2.245 0 01-2.274 2.274 2.244 2.244 0 01-2.272-2.274v-2.273zM12.498 32.955H23.86v14.771H12.498V32.955z"
        fill={fill}
      />
      <path
        d="M40.337 27.27c-2.498 0-4.542 2.049-4.542 4.547v2.274h-.567c-.522 0-.976.354-1.103.86l-3.41 13.635A1.136 1.136 0 0031.818 50h17.045c.74 0 1.284-.696 1.103-1.414l-3.41-13.635a1.137 1.137 0 00-1.103-.86h-.57v-2.274a4.563 4.563 0 00-4.546-4.547zm0 2.274c1.279 0 2.274.994 2.274 2.273v2.274h-4.542v-2.274a2.24 2.24 0 012.268-2.273zm-4.22 6.82h7.995l-1.325 7.952H40.34v2.274h2.069l-.19 1.136h-8.947l2.845-11.362zm9.56 4.435l1.731 6.927h-2.883l1.152-6.927zM15.908 40.34a1.136 1.136 0 11-2.273 0 1.136 1.136 0 012.273 0z"
        fill={fill}
      />
      <path d="M36.363 44.316v2.274h2.274v-2.274h-2.274z" fill={fill} />
    </svg>
  );
}

SvgStore.propTypes = {
  fill: PropTypes.string,
};

SvgStore.defaultProps = {
  fill: '#CCC',
};

export default SvgStore;
