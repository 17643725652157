import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
const TransparentButton = styled.button`
  background: none;
  border: none;
`;
export const BackButton = ({ children, link }) => {
  const history = useHistory();
  const goBack = useCallback(() => (link ? history.push(link) : history.goBack()), [history, link]);
  return <TransparentButton onClick={goBack}>{children}</TransparentButton>;
};
