import React from 'react';
import PropTypes from 'prop-types';

function SvgPhone({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 20" fill="none" {...props}>
      <path
        d="M18 16.624l-4.376-4.061s-2.26 2.503-3.27 2.125c0 0-3.173-1.842-5.577-6.895C4.297 6.848 7.614 5.1 7.614 5.1L4.537 0S-.32 2.409.017 5.148c.336 2.786 2.548 10.343 11.3 14.546 3.029 1.512 6.683-3.07 6.683-3.07z"
        fill={fill}
      />
    </svg>
  );
}

SvgPhone.propTypes = {
  fill: PropTypes.string,
};

SvgPhone.defaultProps = {
  fill: '#000',
};

export default SvgPhone;
