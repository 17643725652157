import React from 'react';
import PropTypes from 'prop-types';

function SvgCamera({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 25" fill="none" {...props}>
      <path
        d="M26.236 19.225c0 2.157-1.72 3.911-3.835 3.911H5.627c-2.114 0-3.835-1.754-3.835-3.91V9.246c0-2.156 1.72-3.91 3.835-3.91H8.03l.824-.586.681-1.9a1.924 1.924 0 011.72-1.024h5.413a1.98 1.98 0 011.756 1.097l.43.804 1.577-.877-.43-.804C19.319.767 18.064 0 16.667 0H11.29C9.892 0 8.602.804 7.957 2.047l-.538 1.498H5.627C2.51 3.545 0 6.104 0 9.284v9.978C0 22.442 2.509 25 5.627 25h16.774c3.119 0 5.628-2.558 5.628-5.738v-7.676h-1.793v7.64z"
        fill={fill}
      />
      <path
        d="M13.979 6.616c-3.728 0-6.774 3.106-6.774 6.907 0 3.802 3.046 6.908 6.774 6.908 3.727 0 6.774-3.106 6.774-6.908 0-3.8-3.047-6.907-6.774-6.907zm0 11.988c-2.76 0-4.982-2.266-4.982-5.08 0-2.815 2.222-5.081 4.982-5.081 2.76 0 4.982 2.266 4.982 5.08 0 2.815-2.222 5.08-4.982 5.08zM26.523 4.13V.585h-1.792V4.13h-3.476v1.828h3.476v3.508h1.792V5.958H30V4.13h-3.477z"
        fill={fill}
      />
    </svg>
  );
}

SvgCamera.propTypes = {
  fill: PropTypes.string,
};

SvgCamera.defaultProps = {
  fill: '#000',
};

export default SvgCamera;
