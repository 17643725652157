import React from 'react';
import PropTypes from 'prop-types';

function SvgMallbly({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 95 31" fill="none" {...props}>
      <path
        d="M4.595 8.15l.132 1.773C5.85 8.546 7.368 7.857 9.282 7.857c2.041 0 3.443.806 4.204 2.417 1.114-1.61 2.7-2.417 4.761-2.417 1.719 0 2.998.503 3.838 1.51.84.995 1.26 2.5 1.26 4.51V24h-4.248V13.893c0-.899-.176-1.553-.528-1.963-.351-.42-.971-.63-1.86-.63-1.27 0-2.149.605-2.637 1.816L14.087 24H9.854V13.907c0-.918-.181-1.582-.542-1.992-.362-.41-.977-.615-1.846-.615-1.201 0-2.07.498-2.608 1.494V24H.625V8.15h3.97zM35.796 24c-.195-.38-.337-.855-.425-1.42-1.025 1.142-2.358 1.713-3.999 1.713-1.553 0-2.842-.45-3.867-1.348-1.016-.898-1.524-2.03-1.524-3.398 0-1.68.62-2.969 1.86-3.867 1.25-.899 3.053-1.353 5.406-1.363h1.948v-.908c0-.732-.19-1.318-.571-1.758-.371-.44-.962-.659-1.772-.659-.713 0-1.275.171-1.685.513-.4.342-.6.81-.6 1.406h-4.234c0-.918.283-1.767.85-2.549.566-.781 1.367-1.391 2.402-1.83 1.035-.45 2.197-.675 3.486-.675 1.953 0 3.501.494 4.644 1.48 1.152.976 1.728 2.353 1.728 4.13v6.87c.01 1.505.22 2.643.63 3.414V24h-4.277zm-3.501-2.944c.625 0 1.201-.137 1.728-.41.528-.284.918-.66 1.172-1.128v-2.725h-1.582c-2.119 0-3.247.732-3.383 2.197l-.015.25c0 .527.185.961.556 1.303.372.342.88.513 1.524.513zM47.163 24h-4.248V1.5h4.248V24zM55.132 24h-4.248V1.5h4.248V24zM72.476 16.251c0 2.422-.557 4.37-1.67 5.845-1.114 1.465-2.608 2.197-4.483 2.197-2.002 0-3.55-.708-4.643-2.124L61.548 24h-2.49V1.5h2.71v8.394c1.093-1.358 2.602-2.037 4.526-2.037s3.433.728 4.526 2.183c1.104 1.455 1.656 3.447 1.656 5.977v.234zm-2.71-.308c0-1.845-.357-3.271-1.07-4.277-.713-1.006-1.738-1.509-3.076-1.509-1.787 0-3.071.83-3.852 2.49v6.856c.83 1.66 2.124 2.49 3.881 2.49 1.3 0 2.31-.503 3.033-1.509.722-1.006 1.084-2.52 1.084-4.54zM78.848 24h-2.71V1.5h2.71V24zM88.384 20.03l3.691-11.88h2.9l-6.371 18.296c-.987 2.637-2.554 3.955-4.703 3.955l-.512-.044-1.011-.19V27.97l.732.058c.918 0 1.631-.185 2.139-.556.518-.371.942-1.05 1.274-2.037l.601-1.61L81.47 8.15h2.959l3.955 11.88z"
        fill={fill}
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M27.637 6a3 3 0 100-6 3 3 0 000 6z" fill="#FFE500" />
    </svg>
  );
}

SvgMallbly.propTypes = {
  fill: PropTypes.string,
};

SvgMallbly.defaultProps = {
  fill: '#000',
};

export default SvgMallbly;
