import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { useTransition } from 'react-spring';
import { Box } from 'rebass/styled-components';
import { Handler, HandlerWrapper, Overlay, TextWrapper, Title, Wrapper } from './styled';

export const SlideModal = ({ children, open, title, toggle }) => {
  const node = createRef();

  const onToggle = (e, force) => {
    e.stopPropagation();
    if (force || !node.current.contains(e.target)) {
      toggle();
    }
  };

  const transitions = useTransition(open, null, {
    from: {
      opacity: 0,
      transform: 'translateY(100%)',
    },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(100%)' },
    trail: 100,
  });

  return transitions.map(({ item, key, props }) => {
    return (
      item && (
        <Overlay key={key} style={{ opacity: props.opacity }} onClick={onToggle}>
          <Wrapper ref={node} style={{ transform: props.transform }}>
            {title && (
              <TextWrapper>
                <Title>{title}</Title>
              </TextWrapper>
            )}
            <HandlerWrapper>
              <Handler />
            </HandlerWrapper>
            <Box flexDirection="column">{children}</Box>
          </Wrapper>
        </Overlay>
      )
    );
  });
};

SlideModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};
