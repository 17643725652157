import React from 'react';

function SvgLogo(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 96 30" fill="none" {...props}>
      <path
        d="M28.018 5.456L16.954.369c-1.046-.492-2.807-.492-3.908 0L1.982 5.456C.88 5.948 0 7.316 0 8.519V21.48c0 1.204.88 2.571 1.982 3.063l11.064 5.087c1.046.492 2.807.492 3.908 0l11.064-5.087C29.12 24.052 30 22.684 30 21.481V8.52c0-1.204-.88-2.571-1.982-3.063zm-1.816 4.868l-8.367 3.828c-.716.328-1.321 1.258-1.321 2.024v8.806c0 .765-.66 1.422-1.431 1.422h-.166c-.77 0-1.43-.657-1.43-1.422v-8.806c0-.766-.606-1.696-1.322-2.024l-8.367-3.828c-.715-.329-1.046-1.204-.715-1.915l.055-.164c.33-.71 1.21-1.039 1.926-.71l8.587 3.937c.716.328 1.927.328 2.643 0l8.587-3.938c.715-.328 1.596 0 1.926.711l.11.164c.33.711 0 1.586-.715 1.915z"
        fill="#FFE500"
      />
      <path
        d="M40 4h3.424v12.27l6.187-6.317h4.205l-5.947 5.742 6.517 9.882h-4.174l-4.746-7.585-2.042 1.994v5.59H40V4zM56.132 4h3.424v3.264h-3.424V4zm0 5.953h3.424v15.624h-3.424V9.953zM76.922 25.577h-3.364V23.4h-.06c-.42.786-1.051 1.42-1.892 1.904-.821.463-1.662.695-2.523.695-2.043 0-3.524-.504-4.445-1.511-.901-1.027-1.352-2.569-1.352-4.624V9.953h3.424v9.58c0 1.37.26 2.337.78 2.901.522.564 1.252.846 2.193.846.721 0 1.322-.11 1.802-.332.481-.222.871-.514 1.172-.877.3-.382.51-.836.63-1.36a6.53 6.53 0 00.21-1.692V9.953h3.425v15.624zM80.623 4h3.423v7.978h.06c.24-.383.531-.725.871-1.027a5.16 5.16 0 011.112-.756c.42-.222.85-.383 1.291-.483a5.31 5.31 0 011.352-.182c1.221 0 2.282.222 3.183.665a6.072 6.072 0 012.283 1.783c.6.745 1.051 1.622 1.352 2.63.3 1.006.45 2.084.45 3.233 0 1.047-.14 2.065-.42 3.052a7.742 7.742 0 01-1.232 2.629 6.24 6.24 0 01-2.072 1.813c-.841.443-1.832.665-2.974.665-.52 0-1.05-.04-1.591-.12a5.509 5.509 0 01-2.824-1.18c-.38-.322-.7-.745-.96-1.269h-.06v2.146h-3.245V4zm11.953 13.78c0-.705-.09-1.39-.27-2.055a5.416 5.416 0 00-.811-1.752 3.984 3.984 0 00-1.352-1.24c-.54-.322-1.161-.483-1.862-.483-1.441 0-2.533.504-3.274 1.511-.72 1.007-1.08 2.347-1.08 4.02 0 .785.09 1.52.27 2.205.2.665.49 1.24.87 1.723.381.483.832.866 1.352 1.148.54.282 1.161.423 1.862.423.781 0 1.442-.161 1.982-.483.541-.323.982-.736 1.322-1.24.36-.523.61-1.107.75-1.752.161-.665.241-1.34.241-2.025z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgLogo;
