import { getVisits } from 'api/visits';
import * as t from '../types';

export const getUserVisitsAction = () => async (dispatch, getState) => {
  try {
    const { id } = getState().user.store;
    dispatch({ type: t.GET_USER_VISITS_REQUEST });

    await getVisits(id, (data, error) => {
      if (data) {
        dispatch({ type: t.GET_USER_VISITS_SUCCESS, payload: data });
      } else {
        throw error;
      }
    });
  } catch (e) {
    dispatch({ type: t.GET_USER_VISITS_FAILURE });
  }
};
