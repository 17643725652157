import * as api from 'api/order';
// import { FullStoryAPI } from 'react-fullstory';
import * as t from '../types';
import Axios from 'lib/utils/axios';

export const getUserOrdersAction = params => async dispatch => {
  try {
    dispatch({ type: t.GET_USER_ORDERS_REQUEST });
    const { data } = await Axios.get('/order', {
      params,
    }).then(({ data }) => data);
    dispatch({ type: t.GET_USER_ORDERS_SUCCESS, payload: data.orders });
  } catch (e) {
    dispatch({ type: t.GET_USER_ORDERS_FAILURE });
  }
};

export const getUserInvoicesAction = () => async (dispatch, getState) => {
  try {
    const { store } = getState().user.profile;
    dispatch({ type: t.GET_USER_INVOICES_REQUEST });

    await api.getUserInvoices(store, (data, error) => {
      if (data) {
        dispatch({ type: t.GET_USER_INVOICES_SUCCESS, payload: data });
      } else {
        throw error;
      }
    });
  } catch (e) {
    dispatch({ type: t.GET_USER_INVOICES_FAILURE });
  }
};

export const getOrderByIdAction = id => async dispatch => {
  try {
    dispatch({ type: t.GET_USER_ORDER_REQUEST });
    const { data } = await Axios.get(`/order/${id}`).then(({ data }) => data);
    dispatch({ type: t.GET_USER_ORDER_SUCCESS, payload: data });
  } catch (e) {
    dispatch({ type: t.GET_USER_ORDER_FAILURE });
  }
};

export const viewOrderAction = id => async dispatch => {
  try {
    await Axios.put(`/order/${id}/view`);
  } catch (e) {}
};

export const markOrderAsPaidAction = (orderId, productId, callback = () => {}) => async (dispatch, getState) => {
  try {
    dispatch({ type: t.GET_USER_ORDER_REQUEST });
    await Axios.put(`/order/${orderId}/remove-product`, {
      productId,
    });
    dispatch(getOrderByIdAction(orderId));
  } catch (e) {
    callback(null, e);
    dispatch({ type: t.MARK_AS_PAID_FAILURE, payload: productId });
  }
};

export const markOrderAsDeliveredAction = (orderId, callback = () => {}) => async (dispatch, getState) => {
  try {
    dispatch({ type: t.GET_USER_ORDER_REQUEST });
    await Axios.put(`/order/${orderId}/deliver`);
    dispatch(getOrderByIdAction(orderId));
  } catch (e) {
    callback(null, e);
  }
};

export const markOrderAsCancelledAction = (orderId, callback = () => {}) => async (dispatch, getState) => {
  try {
    dispatch({ type: t.GET_USER_ORDER_REQUEST });
    await Axios.put(`/order/${orderId}/cancel`);
    dispatch(getOrderByIdAction(orderId));
  } catch (e) {
    callback(null, e);
  }
};
