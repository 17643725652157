import React from 'react';
import PropTypes from 'prop-types';

function SvgDeliveryBox({ fill, ...props }) {
  return (
    <svg width="27" height="18" viewBox="0 0 27 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M26.5168 2.79036L18.2463 0.0433735C18.1042 -0.0144578 17.9621 -0.0144578 17.7916 0.0433735L9.52105 2.79036C9.23684 2.87711 9.03789 3.16626 9.03789 3.48434V14.5301C9.03789 14.8482 9.23684 15.1084 9.52105 15.2241L17.7916 17.9711C17.8768 18 17.9337 18 18.0189 18C18.1042 18 18.161 18 18.2463 17.9711L26.5168 15.1952C26.801 15.1084 27 14.8193 27 14.5012V3.48434C27 3.16626 26.801 2.90602 26.5168 2.79036ZM17.3084 16.294L10.4589 14.0096V4.46747L13.1874 5.36386V10.7711C13.1874 11.1759 13.5 11.494 13.8979 11.494C14.2958 11.494 14.6084 11.1759 14.6084 10.7711V5.85542L17.3368 6.75181V16.294H17.3084ZM18.0189 5.47952L16.0863 4.84337L17.4505 4.35181C17.82 4.20723 18.0189 3.80241 17.8768 3.42651C17.7347 3.0506 17.3368 2.87711 16.9674 2.99277L13.9263 4.12048L12.0221 3.48434L18.0189 1.48916L24.0158 3.48434L18.0189 5.47952ZM25.5789 14.0096L22.8505 14.906V11.7831C22.8505 11.3783 22.5379 11.0602 22.14 11.0602C21.7421 11.0602 21.4579 11.3783 21.4579 11.7831V15.3976L18.7295 16.294V6.75181L25.5789 4.46747V14.0096Z"
        fill={fill}
      />
      <path
        d="M5.74105 4.26501H0.710526C0.312632 4.26501 0 4.58309 0 4.98791C0 5.39273 0.312632 5.7108 0.710526 5.7108H5.74105C6.13895 5.7108 6.45158 5.39273 6.45158 4.98791C6.42316 4.58309 6.11053 4.26501 5.74105 4.26501Z"
        fill={fill}
      />
      <path
        d="M5.74105 8.2843H0.710526C0.312632 8.2843 0 8.60237 0 9.00719C0 9.41201 0.312632 9.73008 0.710526 9.73008H5.74105C6.13895 9.73008 6.45158 9.41201 6.45158 9.00719C6.45158 8.60237 6.11053 8.2843 5.74105 8.2843Z"
        fill={fill}
      />
      <path
        d="M5.74105 12.3036H0.710526C0.312632 12.3036 0 12.6217 0 13.0265C0 13.4313 0.312632 13.7494 0.710526 13.7494H5.74105C6.13895 13.7494 6.45158 13.4313 6.45158 13.0265C6.45158 12.6217 6.11053 12.3036 5.74105 12.3036Z"
        fill={fill}
      />
    </svg>
  );
}

SvgDeliveryBox.propTypes = {
  fill: PropTypes.string,
};

SvgDeliveryBox.defaultProps = {
  fill: 'black',
};

export default SvgDeliveryBox;
