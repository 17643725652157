import { fireStore, timestamp } from 'services/firebase';

const visitsCollection = fireStore.collection('storeVisits');
const visitorsCollection = fireStore.collection('visitors');

export const getVisits = async (id, callback) => {
  try {
    const visitsQuery = await visitsCollection.doc(id).get();
    const visitorsQuery = await visitorsCollection.where('store', '==', id).get();
    const docs = visitorsQuery.docs || [];
    const returning = docs.reduce((acc, doc) => {
      acc += doc.data().count > 1 ? 1 : 0;
      return acc;
    }, 0);

    const { visitCount: total } = visitsQuery.data();
    const data = {
      total,
      returning,
    };
    callback(data, null);
  } catch (e) {
    callback(null, e);
  }
};

export const visitStore = async (id, fingerprint) => {
  try {
    const visitsQuery = await visitsCollection.doc(id).get();
    const visitorsQuery = await visitorsCollection.get();
    const { visitCount = 0 } = visitsQuery.data();
    if (visitorsQuery.empty) {
      await visitsCollection.doc(id).update({ visitCount: visitCount + 1 });
      const { id: visitorId } = await visitorsCollection.add({
        count: 1,
        fingerprint,
        store: id,
        created: timestamp,
      });
      await visitorsCollection.doc(visitorId).update({
        id: visitorId,
      });
    } else {
      const data = visitorsQuery.docs[0].data();
      await visitorsCollection.doc(data.id).update({
        count: data.count + 1,
        modified: timestamp,
      });
    }
  } catch (e) {
    console.log({ e });
  }
};
