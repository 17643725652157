export const GET_USER_INVOICES_REQUEST = 'GET_USER_INVOICES_REQUEST';
export const GET_USER_INVOICES_SUCCESS = 'GET_USER_INVOICES_SUCCESS';
export const GET_USER_INVOICES_FAILURE = 'GET_USER_INVOICES_FAILURE';

export const GET_USER_ORDERS_REQUEST = 'GET_USER_ORDERS_REQUEST';
export const GET_USER_ORDERS_SUCCESS = 'GET_USER_ORDERS_SUCCESS';
export const GET_USER_ORDERS_FAILURE = 'GET_USER_ORDERS_FAILURE';

export const GET_USER_ORDER_REQUEST = 'GET_USER_ORDER_REQUEST';
export const GET_USER_ORDER_SUCCESS = 'GET_USER_ORDER_SUCCESS';
export const GET_USER_ORDER_FAILURE = 'GET_USER_ORDER_FAILURE';

export const MARK_AS_PAID_REQUEST = 'MARK_AS_PAID_REQUEST';
export const MARK_AS_PAID_SUCCESS = 'MARK_AS_PAID_SUCCESS';
export const MARK_AS_PAID_FAILURE = 'MARK_AS_PAID_FAILURE';
