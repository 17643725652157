import * as t from '../types';

const INITIAL_STATE = {
  data: [],
  paging: {},
  loading: false,
  loadingMore: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.GET_INSTAGRAM_PICTURES_REQUEST:
      return { ...state, loading: true };

    case t.GET_INSTAGRAM_PICTURES_SUCCESS:
      return { ...state, loading: false, ...action.payload };

    case t.GET_INSTAGRAM_PICTURES_FAILURE:
      return { ...state, loading: false };

    case t.GET_MORE_INSTAGRAM_PICTURES_REQUEST:
      return { ...state, loadingMore: true };

    case t.GET_MORE_INSTAGRAM_PICTURES_SUCCESS:
      return { ...state, loadingMore: false, ...action.payload };

    case t.GET_MORE_INSTAGRAM_PICTURES_FAILURE:
      return { ...state, loadingMore: false };

    default:
      return { ...state };
  }
};
