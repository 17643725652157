import styled from 'styled-components';
import { Input } from './Input';
import { PhoneInput } from './PhoneInput';
import { Searchbar } from './Searchbar';
import { Select } from './Select';
import { SelectMultiple } from './SelectMultiple';
import { AddMultiple } from './AddMultiple';
import { Switch } from './Switch';
import { Textarea } from './Textarea';
import { Toggle } from './Toggle';
import { VariantSelector } from './VariantSelector';
import { CountrySelector } from './CountrySelector';
import { SortSelect } from './SortSelect';

const Form = styled.form``;

Form.Input = Input;
Form.PhoneInput = PhoneInput;
Form.Searchbar = Searchbar;
Form.Switch = Switch;
Form.Textarea = Textarea;
Form.Toggle = Toggle;
Form.VariantSelector = VariantSelector;
Form.Select = Select;
Form.SelectMultiple = SelectMultiple;
Form.CountrySelector = CountrySelector;
Form.SortSelect = SortSelect;
Form.AddMultiple = AddMultiple;

export { Form };
