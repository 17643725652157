import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { RequiredIcon, TickIcon, VoidIcon } from 'components/icons';
import Typography, { Text } from '../Typography';
import { SlideModal as Modal } from 'components/molecules/SlideModal';
import { Searchbar } from './Searchbar';
import { getWindowDimension } from 'lib/utils';
import { countries } from 'lib/config/data';
import { AsYouType } from 'libphonenumber-js';

const activeLabel = css`
  bottom: 4rem;
  color: ${props => props.theme.colors.ash};
  font-size: 1.2rem;
  text-transform: uppercase;

  ${({ theme }) => theme.mq.md`
    bottom: 3.2rem;
  `}
`;

const StyledFlagDropDown = styled.div`
  outline: none;
  padding: 0;
  border: 1px solid #cacaca;
  height: 125%;
  &.open {
    z-index: 2;
    background: #fff;
    .selected-flag {
      background: #fff;
    }
  }
  &:hover,
  &:focus,
  &.open {
    cursor: pointer;
    .selected-flag {
      background-color: #f5f5f5;
    }
  }

  .selected-flag {
    position: relative;
    width: 38px;
    height: 100%;
    padding: 0 0 0 8px;
    border-radius: 3px 0 0 3px;
    .flag {
      position: absolute;
      top: 50%;
      margin-top: -5px;
    }
    .arrow {
      position: relative;
      top: 50%;
      margin-top: -2px;
      left: 20px;
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 4px solid #555;
      &.up {
        border-top: none;
        border-bottom: 4px solid #555;
      }
    }
  }
`;

const DivComponent = styled.div`
  color: ${props => props.theme.colors.black};
  display: block;
  font-size: 2rem;
  height: 2.5rem;
  line-height: 1;
  margin: 0;
  padding: 0;
  position: relative;
  transition: all 0.2s;
  width: 100%;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mq.md`
    font-size: 2rem;
    height: 2.2rem;
  `}

  &>div {
    &:nth-of-type(2) {
      margin: auto 6px auto 1em;
    }
  }

  input {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearence: none;
    background-color: transparent;
    border-radius: 0;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    width: 100%;
    color: inherit;
    line-height: inherit;
  }
`;

const LabelComponent = styled.label`
  align-items: center;
  bottom: 1rem;
  color: ${props => props.theme.colors.black};
  display: flex;
  font-size: 2rem;
  left: 0;
  line-height: 1;
  pointer-events: none;
  position: absolute;
  transition: all 0.2s;
  width: 100%;
`;

const Error = styled(Text)`
  color: ${props => props.theme.colors.error};
  font-size: 13px;
  left: 0;
  position: absolute;
  top: calc(100% + 0.5rem);
`;

const Label = ({ error, text, subText, required }) => (
  <LabelComponent>
    <Typography.Text style={{ display: 'inline-flex', alignItems: 'center' }} color={!error ? 'black' : 'error'}>
      {text}
      {required && <RequiredIcon width={5} height={5} style={{ marginLeft: '1rem' }} />}
    </Typography.Text>

    {subText ? (
      <Typography.Text size="tiny" color="black" style={{ marginLeft: 'auto' }}>
        {subText}
      </Typography.Text>
    ) : null}
  </LabelComponent>
);

const Group = styled.div`
  align-items: flex-end;
  border-bottom: 1px solid ${props => props.theme.colors.black};
  display: flex;
  height: ${props => (props.alwaysActive ? '6.5rem' : '6rem')};
  margin-bottom: 3rem;
  padding-bottom: 1rem;
  position: relative;
  width: 100%;
  background-color: ${props => (props.error ? '#fcf2f2' : 'initial')};

  label {
    bottom: ${props => (props.alwaysActive ? '4.5rem' : '4rem')} !important;
  }

  .react-tel-input {
    .form-control {
      background-color: transparent;
      border: none;
      font-size: 2rem;
      height: unset;
      width: 100%;
    }
  }

  input {
    &::placeholder {
      opacity: 0;
    }
  }

  ${props => {
    if (props.dirty) {
      return css`
        background-color: ${props.error ? '#fcf2f2' : props.theme.colors.grey};
        ${LabelComponent} {
          ${activeLabel};
        }
      `;
    }
  }}

  ${({ alwaysActive }) =>
    alwaysActive &&
    css`
      ${LabelComponent} {
        ${activeLabel};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      border-bottom-color: #b3b3b3;
      cussor: not-allowed;

      ${DivComponent},
      label, span {
        color: #b3b3b3;
      }
    `}
`;

const StyledSearchbar = styled(Searchbar)`
  background: #fff;

  & > input {
    &::placeholder {
      color: #999999;
      opacity: 1 !important;
    }
  }
`;

const StyledOptionsList = styled.div`
  width: 100%;
  padding-top: 0.94rem;
  height: 14em;
  overflow: auto;
`;

const OptionsContainer = styled.div`
  background: #fff;
  background: #f5f5f5;

  ${({ normal }) =>
    normal
      ? `
        position: absolute;
        width: 100%;
        top: 110%;
        z-index: 1;
        box-shadow: 0px 10px 10px 7px rgba(0, 0, 0, 0.07);
        
        ${StyledOptionsList} {
          height: 200px;
        }
      `
      : `
        width: 100%;
        height: max-content;
        
        ${StyledOptionsList} {
          height: 40rem;
        }
      `}
`;

const StyledOptionItem = styled.div`
  width: 100%;
  font-size: 1.4rem;
  line-height: 130%;
  padding: 1.071em 1.429em;
  font-weight: normal;
  color: ${props => (props.active ? '#000000' : ' #888888')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      margin: 0 1rem;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
  }
`;

const OptionItem = ({ active, name, code, abbv, onSelect }) => {
  const selectedRef = useRef(null);
  useEffect(() => {
    const selected = selectedRef.current;
    if (selected) {
      selected.scrollIntoView();
    }
  }, []);
  return (
    <StyledOptionItem
      ref={active ? selectedRef : null}
      active={active}
      onClick={e => onSelect(e, { name, code, abbv })}
    >
      <div>
        <div className={`flag ${abbv}`}></div>
        <span className="country-name">{name}</span>
        <span className="dial-code">+{code}</span>
      </div>
      {active ? <TickIcon /> : <VoidIcon />}
    </StyledOptionItem>
  );
};

export const OptionsList = ({ options, onSelect, selected, onClose, optionComponent: C }) => {
  const [term, setTerm] = useState('');
  const modalOption = getWindowDimension().width < 768;
  const handleSearch = ({ target: { value } }) => {
    setTerm(value.toLowerCase());
  };

  const filteredOptions = options.filter(item => item.name.toLowerCase().includes(term));

  const handleOptionSelect = (e, value) => {
    e.stopPropagation();
    return onSelect(value);
  };

  const renderOptions = () => (
    <OptionsContainer normal={!modalOption}>
      <StyledSearchbar value={term} onChange={handleSearch} />
      <StyledOptionsList>
        {filteredOptions.map((item, index) => {
          const { abbv } = item;
          const active = selected === abbv;
          return <C key={index} active={active} {...item} onSelect={handleOptionSelect} />;
        })}
      </StyledOptionsList>
    </OptionsContainer>
  );

  return modalOption ? (
    <Modal toggle={onClose} open={true}>
      {renderOptions()}
    </Modal>
  ) : (
    renderOptions()
  );
};

export const PhoneInput = ({
  className,
  disabled,
  error,
  label,
  labelInfo,
  showRequired,
  style,
  value,
  onChange,
  placeholder,
}) => {
  const [state, setStateOrigin] = useState({
    value: '',
    showOptions: false,
    modalOption: false,
    selectedCountry: {
      name: 'Nigeria',
      code: '234',
      abbv: 'ng',
    },
  });

  const selectRef = useRef(null);

  const setState = useCallback(
    change => {
      const newState = typeof change === 'function' ? { ...state, ...change(state) } : { ...state, ...change };
      return setStateOrigin(newState);
    },
    [state],
  );

  const handleOptionSelect = selectedCountry => {
    onChange(`+${selectedCountry.code} ${state.value}`);
    return setState({ selectedCountry, showOptions: !state.showOptions });
  };

  const handleOnChange = ({ target: { value } }) => {
    value = value.split(' ').join('');
    if (isNaN(value)) {
      return setState(({ value }) => ({ value }));
    }
    if (value.length > 10) return;
    if (value.charAt(0) === '0') return;
    const formattedNumb = new AsYouType().input(`+${state.selectedCountry.code}${value}`);
    onChange(formattedNumb);
    const valueArr = formattedNumb.split(' ');
    valueArr.shift();
    return setState({ value: valueArr.join(' ') });
  };

  // const getSelectText = () => {
  //   const selectedOption = options.find(item => item.value === state.value);
  //   if (selectedOption) return selectedOption.text;
  //   return placeholder;
  // };

  const handleClickOutside = useCallback(
    event => {
      if (!selectRef.current.contains(event.target)) {
        setState({
          showOptions: false,
        });
      }
    },
    [setState],
  );

  const handleToggle = () => setState(({ showOptions }) => ({ showOptions: !showOptions }));

  useEffect(() => {
    if (value) {
      const initValue = value => {
        const formattedNumb = new AsYouType().input(value.split(' ').join(''));
        const strArr = formattedNumb.split(' ');
        strArr.shift();
        return strArr.join(' ');
      };
      setState({ value: initValue(value) });
    }

    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <Group
      alwaysActive={true}
      className={className}
      disabled={disabled}
      error={error.length}
      style={style}
      ref={selectRef}
    >
      <DivComponent disabled={disabled}>
        <StyledFlagDropDown onClick={handleToggle} className={state.showOptions ? 'open' : ''}>
          <div className="selected-flag" title={`${state.selectedCountry.name}: ${state.selectedCountry.code}`}>
            <div className={`flag ${state.selectedCountry.abbv}`}>
              <div className={`arrow${state.showOptions ? ` up` : ''}`}></div>
            </div>
          </div>
        </StyledFlagDropDown>
        <div>{`+${state.selectedCountry.code}`}</div>
        <input value={state.value} type="text" onChange={handleOnChange} placeholder={placeholder} />
      </DivComponent>
      <Label error={error.length} required={showRequired} subText={labelInfo} text={label} />
      {error && <Error>{error}</Error>}
      {state.showOptions && (
        <OptionsList
          options={countries}
          onSelect={handleOptionSelect}
          onClose={handleToggle}
          selected={state.selectedCountry.abbv}
          open={state.showOptions}
          optionComponent={OptionItem}
        />
      )}
    </Group>
  );
};

PhoneInput.defaultProps = {
  className: '',
  disabled: false,
  error: '',
  labelInfo: '',
  showRequired: false,
  style: {},
  modalOption: false,
  placeholder: 'Select',
  onChange: () => null,
};

PhoneInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelInfo: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showRequired: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
  value: PropTypes.string.isRequired,
  modalOption: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
};
