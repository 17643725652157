import * as t from '../types';
import { REHYDRATE } from 'redux-persist';

export default (state = {}, { type, store, cart, payload }) => {
  switch (type) {
    case t.SET_CART:
      return { ...state, [store]: cart };

    case t.RESET_CART:
      const newState = { ...state };
      delete newState[store];
      return { ...newState };
    case REHYDRATE:
      const persistedCart = payload?.cart || {};
      return { ...state, ...persistedCart };
    default:
      return { ...state };
  }
};
