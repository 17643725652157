import * as t from '../types';
const viewedProducts = localStorage.getItem('viewedProducts') ? JSON.parse(localStorage.getItem('viewedProducts')) : [];
const INITIAL_STATE = {
  creating: false,
  deleting: false,
  loading: false,
  userProducts: [],
  publicProducts: {
    data: [],
    filters: {},
    pagination: {
      page: 1,
      limit: 50,
    },
  },
  productDetail: {
    images: [],
    variants: [],
  },
  viewedProducts,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.CREATE_PRODUCT_REQUEST:
      return { ...state, loading: true };

    case t.CREATE_PRODUCT_SUCCESS:
      return { ...state, loading: false };

    case t.CREATE_PRODUCT_FAILURE:
      return { ...state, loading: false };

    case t.DELETE_PRODUCT_REQUEST:
      return { ...state, deleting: true };

    case t.DELETE_PRODUCT_SUCCESS:
      return { ...state, deleting: false };

    case t.DELETE_PRODUCT_FAILURE:
      return { ...state, deleting: false };

    case t.GET_USER_PRODUCTS_REQUEST:
      return { ...state, loading: true };

    case t.GET_USER_PRODUCTS_SUCCESS:
      return { ...state, loading: false, userProducts: action.payload };

    case t.GET_USER_PRODUCTS_FAILURE:
      return { ...state, loading: false };

    case t.FETCH_PRODUCT_PUBLIC_REQUEST:
      return { ...state, loading: true };

    case t.FETCH_PRODUCT_PUBLIC_SUCCESS:
      return {
        ...state,
        loading: false,
        publicProducts: {
          ...state.publicProducts,
          data: action.payload,
        },
      };

    case t.FETCH_PRODUCT_PUBLIC_FAILURE:
      return { ...state, loading: false };

    case t.FETCH_PRODUCT_DETAILS_REQUEST:
      return { ...state, loading: true };

    case t.FETCH_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        productDetail: action.payload.product,
        viewedProducts: action.payload.viewedProducts,
      };

    case t.FETCH_PRODUCT_DETAILS_FAILURE:
      return { ...state, loading: false };

    default:
      return { ...state };
  }
};
