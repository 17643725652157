import React from 'react';
import PropTypes from 'prop-types';

function SvgMark({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" {...props} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7.5" cy="7.5" r="7.5" fill="#28BB8F" />
      <path
        d="M5.01496 7.07975C4.71382 6.79535 4.23915 6.80891 3.95474 7.11004C3.67033 7.41118 3.6839 7.88586 3.98503 8.17026L6.23501 10.2952C6.55675 10.5991 7.07028 10.5598 7.34199 10.2104L10.842 5.71048C11.0963 5.38352 11.0374 4.91232 10.7104 4.65802C10.3834 4.40372 9.91224 4.46262 9.65794 4.78958L6.66478 8.63792L5.01496 7.07975Z"
        fill={fill}
      />
    </svg>
  );
}

SvgMark.propTypes = {
  fill: PropTypes.string,
};

SvgMark.defaultProps = {
  fill: '#fff',
};

export default SvgMark;
