import React from 'react';
import PropTypes from 'prop-types';

function SvgVoid({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" {...props} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7.5" cy="7.5" r="7.5" fill="#C4C4C4" />
    </svg>
  );
}

SvgVoid.propTypes = {
  fill: PropTypes.string,
};

SvgVoid.defaultProps = {
  fill: '#fff',
};

export default SvgVoid;
