import React from 'react';
import PropTypes from 'prop-types';

function SvgCross({ fill, ...props }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.4667 8.46667H11.5333V1.53333C11.5333 0.688889 10.8444 0 10 0C9.15556 0 8.46667 0.688889 8.46667 1.53333V8.46667H1.53333C0.688889 8.46667 0 9.15556 0 10C0 10.8444 0.688889 11.5333 1.53333 11.5333H8.46667V18.4667C8.46667 19.3111 9.15556 20 10 20C10.8444 20 11.5333 19.3111 11.5333 18.4667V11.5333H18.4667C19.3111 11.5333 20 10.8444 20 10C20 9.15556 19.3111 8.46667 18.4667 8.46667Z"
        fill={fill}
      />
    </svg>
  );
}

SvgCross.propTypes = {
  fill: PropTypes.string,
};

SvgCross.defaultProps = {
  fill: 'white',
};

export default SvgCross;
