import React from 'react';
import PropTypes from 'prop-types';

function SvgPlus({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M18.467 8.467h-6.934V1.533C11.533.69 10.844 0 10 0S8.467.689 8.467 1.533v6.934H1.533C.69 8.467 0 9.156 0 10s.689 1.533 1.533 1.533h6.934v6.934C8.467 19.31 9.156 20 10 20s1.533-.689 1.533-1.533v-6.934h6.934C19.31 11.533 20 10.844 20 10s-.689-1.533-1.533-1.533z"
        fill={fill}
      />
    </svg>
  );
}

SvgPlus.propTypes = {
  fill: PropTypes.string,
};

SvgPlus.defaultProps = {
  fill: '#000',
};

export default SvgPlus;
