import styled, { css, keyframes } from 'styled-components';
import { darken } from 'polished';
import PropTypes from 'prop-types';

const rotate = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

export const ButtonTemplate = styled.button`
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: ${props => `${props.borderRadius}px`};
  cursor: pointer;
  display: inline-block;
  letter-spacing: ${props => props.letterSpacing};
  line-height: 1;
  outline: none;
  overflow: hidden;
  padding: 0;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  white-space: nowrap;
  ${({ size, theme, fontWeight, fontSize }) =>
    size &&
    css`
      font-size: ${(fontSize && theme.font.size[fontSize]) || theme.buttons[size].fontSize};
      font-weight: ${theme.font.weight[fontWeight]};
      height: ${theme.buttons[size].height};
      padding: ${theme.buttons[size].padding};
    `}


  ${({ block }) =>
    block &&
    css`
      width: 100%;
    `}
  

  ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 500px;
    `}
  

  ${({ color, loading, theme }) =>
    loading === 'true' &&
    css`
      color: transparent !important;
      pointer-events: none;
      position: relative;

      &::after {
        content: '';
        animation: ${rotate} 500ms infinite linear;
        border: 2px solid ${color === 'black' ? theme.colors.white : theme.colors.black};
        border-radius: 50%;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        display: block;
        height: 1em;
        width: 1em;
        position: absolute;
        left: calc(50% - (1em / 2));
        top: calc(50% - (1em / 2));
        -webkit-transform-origin: center;
        transform-origin: center;
        position: absolute !important;
      }
    `}
`;

export const Button = styled(ButtonTemplate)`
  background-color: ${props => props.theme.colors[props.color]};
  &,
  &:visited {
    color: ${props => props.theme.colors.black};
  }

  ${({ color, theme }) =>
    color === 'black' &&
    css`
      &,
      &:visited {
        color: ${theme.colors.white};
      }
    `}


  ${({ color, faded, theme }) =>
    faded &&
    css`
      background-color: ${theme.colors[color]}30;

      &,
      &:visited {
        color: ${theme.colors[color]};
      }
    `}

  ${({ color, raised, theme }) =>
    raised &&
    css`
      box-shadow: 0 4px 0 ${darken(0.2, theme.colors[color])};

      &:hover {
        box-shadow: 0 2px 0 ${darken(0.2, theme.colors[color])};
        transform: translateY(2px);
      }

      &:active {
        box-shadow: none;
        transform: translateY(4px);
      }
    `}}


    &:disabled{
      background-color: ${props => props.theme.colors.grey};
      cursor: not-allowed;
    }
`;

export const OutlineButton = styled(ButtonTemplate)`
  border: 1px solid ${props => props.theme.colors[props.color]};
  &,
  &:visited {
    color: ${props => props.theme.colors[props.color]};
  }

  ${({ color, loading, theme }) =>
    loading === 'true' &&
    css`
      &::after {
        border: 2px solid ${theme.colors[color]};
      }
    `}

  ${({ noBorder }) =>
    noBorder &&
    css`
      border: none;
    `}
`;

Button.defaultProps = {
  borderRadius: 0,
  letterSpacing: '0',
  size: 'normal',
};

Button.propTypes = {
  size: PropTypes.oneOf(['small', 'normal', 'large']).isRequired,
};

Button.propTypes = {
  color: PropTypes.string,
};
Button.defaultProps = {
  color: 'black',
  fontWeight: 'normal',
};

OutlineButton.propTypes = {
  color: PropTypes.string,
};

OutlineButton.defaultProps = {
  color: 'black',
  fontWeight: 'normal',
};
