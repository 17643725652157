import { format } from 'date-fns';
import config from 'lib/config/config';
export const generateUsername = email => {
  return email.split('@')[0];
  // return new Promise(resolve => {
  //   const timestamp = Date.now();
  //   const subDigit = timestamp.toString().substr(-2);
  //   const username = `${firstName.toLowerCase()}.${lastName.toLowerCase()}${subDigit}`;
  //   return resolve(username);
  // });
};

export const hyphenate = str => {
  const newStr = str
    .toLowerCase()
    .replace(/[^A-Z0-9]+/gi, '-')
    .replace()
    .split('');

  if (newStr[0] === '-') newStr.shift();
  if (newStr[newStr.length - 1] === '-') newStr.pop();

  return newStr.join('');
};

export const addDomain = (str = '') => {
  return `${config.frontendBaseUrl}/s/${str}`;
};

export const stripDomain = (str = '') => {
  const newStr = str
    .split('/s/')
    .slice(1)
    .join('/s/');
  return newStr;
};

export const createLinkFromName = str => {
  if (str) {
    return hyphenate(str);
  }
  return str;
};

export const decodeURL = str => {
  return decodeURI(str);
};

export const encodeURL = str => {
  return encodeURI(str);
};

export const flattenArray = arr => [].concat.apply([], arr);

export const checkErrorArray = obj => !flattenArray(Object.values(obj)).length;

export const copyText = text => {
  const el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};

export const createRandomID = () =>
  `_${Math.random()
    .toString(36)
    .substr(2, 9)}`;

export const padDate = n => `0${n}`.slice(-2);

export const daysAgo = days => {
  const date = new Date();
  const last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
  const day = last.getDate();
  const month = last.getMonth() + 1;
  const year = last.getFullYear();
  return `${year}-${padDate(month)}-${padDate(day)}`;
};

export const firstLetterUc = string => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return '';
};

export const numbersOnly = n => {
  if (n) {
    const num = `${n}`;
    const regex = /\D/gm;
    return num.replace(regex, '');
  }
  return '';
};

export const formatNumberWithCommas = n => {
  if (n) {
    const number = numbersOnly(n);
    const parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }
  return '';
};

export const formatToLowerCase = string => string.toLowerCase();

export const formatToUpperCase = string => string.toUpperCase();

export const generateRandomString = stringLength => {
  let string = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < stringLength; i += 1) string += possible.charAt(Math.floor(Math.random() * possible.length));

  return string;
};

export const includes = (id, favorites) => favorites.includes(id);

export const isObjEmpty = (obj = {}) => {
  return !Object.entries(obj).length;
};

export const mapObject = obj => Object.keys(obj).map(key => obj[key]);

export const milliToDay = ms => Math.floor(ms / (1000 * 60 * 60 * 24));

export const removeDuplicateObjectsFromArray = arr => {
  const result = [];
  const duplicatesIndices = [];

  arr.forEach((current, index) => {
    if (duplicatesIndices.includes(index)) return;

    result.push(current);
    for (let comparisonIndex = index + 1; comparisonIndex < arr.length; comparisonIndex += 1) {
      const comparison = arr[comparisonIndex];
      const currentKeys = Object.keys(current);
      const comparisonKeys = Object.keys(comparison);

      // eslint-disable-next-line no-continue
      if (currentKeys.length !== comparisonKeys.length) continue;
      const currentKeysString = currentKeys
        .sort()
        .join('')
        .toLowerCase();
      const comparisonKeysString = comparisonKeys
        .sort()
        .join('')
        .toLowerCase();
      // eslint-disable-next-line no-continue
      if (currentKeysString !== comparisonKeysString) continue;

      let valuesEqual = true;
      for (let i = 0; i < currentKeys.length; i += 1) {
        const key = currentKeys[i];
        if (current[key] !== comparison[key]) {
          valuesEqual = false;
          break;
        }
      }
      if (valuesEqual) duplicatesIndices.push(comparisonIndex);
    }
  });

  return result;
};

export const removeDuplicateStringsFromArray = arr => {
  const set = new Set(arr);
  return [...set];
};

export const replaceHyphenWithSpace = error => {
  if (error) {
    return firstLetterUc(error).replace('_', ' ');
  }
  return '';
};

export const shuffle = array => array.sort(() => Math.random() - 0.5);

export const trim = value => {
  if (value) {
    return value.toString().replace(/ /g, '');
  }
};

export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validateNumber = n => {
  let firstChar;
  let number;
  const pattern = /^([0]{1})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7})$/g;

  if (!n || n.length < 5) return false;

  if (typeof n === 'number') {
    number = `0${n}`;
  } else if (typeof n === 'string') {
    firstChar = n.substring(0, 1);
    number = firstChar === '0' ? n : `0${n}`;
  } else {
    return false;
  }
  return pattern.test(number.replace(/\s+/g, ''));
};

export const validateURL = url => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return pattern.test(url);
};

export const toDateTime = secs => {
  if (secs) {
    const t = new Date(1970, 0, 1);
    t.setSeconds(secs);
    return t;
  }
  return '';
};

export const formatDate = (date, dateFormat = 'dd/MM/yyyy') => {
  if (date) {
    return format(date, dateFormat);
  }
  return '';
};

export const groupByDate = data => {
  const groupObject = data.reduce((groups, item) => {
    const date = item.createdAt.split('T')[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(item);
    return groups;
  }, {});

  const groupArrays = Object.keys(groupObject).map(date => {
    return {
      date,
      items: groupObject[date],
    };
  });

  return groupArrays;
};

export const getWindowDimension = () => {
  var doc = document,
    w = window;
  var docEl = doc.compatMode && doc.compatMode === 'CSS1Compat' ? doc.documentElement : doc.body;

  var width = docEl.clientWidth;
  var height = docEl.clientHeight;

  // mobile zoomed in?
  if (w.innerWidth && width > w.innerWidth) {
    width = w.innerWidth;
    height = w.innerHeight;
  }

  return { width, height };
};

export const generateCountrieStatesLgas = locations => {
  const countries = [...new Set(locations.map(({ country }) => country))];
  const states = [...new Set(locations.map(({ state }) => state))];
  const lgas = [
    ...new Set(
      locations.reduce((acc, { locations }) => {
        acc.push(...locations);
        return acc;
      }, []),
    ),
  ];

  return {
    countries: countries.map(item => ({ text: item, value: item })),
    states: states.map(item => ({ text: item, value: item })),
    lgas: lgas.map(item => ({ text: item, value: item })),
  };
};
