import styled, { css } from 'styled-components';

export const Switch = styled.div`
  background: #999999;
  border-radius: 2rem;
  cursor: pointer;
  display: block;
  height: 2rem;
  position: relative;
  width: 4rem;

  &:after {
    background: #fff;
    border-radius: 1.4rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    content: '';
    height: 1.4rem;
    left: 4px;
    position: absolute;
    top: 3px;
    transition: all 0.2s;
    width: 1.4rem;
  }

  ${({ checked }) =>
    checked &&
    css`
      background: #00cd8d;
      &:after {
        left: calc(100% - 4px);
        transform: translateX(-100%);
      }
    `}
`;
