import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import PhoneInputComponent from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { RequiredIcon } from 'components/icons';
import Typography, { Text } from '../Typography';

const activeLabel = css`
  bottom: 4rem;
  color: ${props => props.theme.colors.ash};
  font-size: 1.2rem;
  text-transform: uppercase;

  ${({ theme }) => theme.mq.md`
    bottom: 3.2rem;
  `}
`;

export const InputComponent = styled.input`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearence: none;
  background-color: transparent;
  border-radius: 0;
  border: none;
  color: ${props => props.theme.colors.black};
  display: block;
  font-size: 2rem;
  height: 2.5rem;
  line-height: 1;
  margin: 0;
  outline: none;
  padding: 0;
  position: relative;
  transition: all 0.2s;
  width: 100%;
  overflow-x: auto;

  ${({ theme }) => theme.mq.md`
    font-size: 2rem;
    height: 2.2rem;
  `}
`;

const StyledPre = styled(Typography.Paragraph)`
  white-space: nowrap;
`;

const PreInputWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  * + * {
    margin-left: 0.5rem;
  }
`;

const LabelComponent = styled.label`
  align-items: center;
  bottom: 1rem;
  color: ${props => props.theme.colors.black};
  display: flex;
  font-size: 2rem;
  left: 0;
  line-height: 1;
  pointer-events: none;
  position: absolute;
  transition: all 0.2s;
  width: 100%;
`;

const Error = styled(Text)`
  color: ${props => props.theme.colors.error};
  font-size: 13px;
  left: 0;
  position: absolute;
  top: calc(100% + 0.5rem);
`;

const Label = ({ error, text, subText, required }) => (
  <LabelComponent>
    <Typography.Text style={{ display: 'inline-flex', alignItems: 'center' }} color={!error ? 'black' : 'error'}>
      {text}
      {required && <RequiredIcon width={5} height={5} style={{ marginLeft: '1rem' }} />}
    </Typography.Text>

    {subText ? (
      <Typography.Text size="tiny" color="black" style={{ marginLeft: 'auto' }}>
        {subText}
      </Typography.Text>
    ) : null}
  </LabelComponent>
);

const Group = styled.div`
  align-items: flex-end;
  border-bottom: 1px solid ${props => props.theme.colors.black};
  display: flex;
  height: ${props => (props.alwaysActive ? '6.5rem' : '6rem')};
  margin-bottom: 3rem;
  padding-bottom: 1rem;
  position: relative;
  width: 100%;
  background-color: ${props => (props.error ? '#fcf2f2' : 'initial')};

  label {
    bottom: ${props => (props.alwaysActive ? '4.5rem' : '4rem')} !important;
  }

  .react-tel-input {
    .form-control {
      background-color: transparent;
      border: none;
      font-size: 2rem;
      height: unset;
      width: 100%;
    }
  }

  input {
    &::placeholder {
      opacity: 0;
    }
  }

  ${props => {
    if (props.pre) {
      return css`
        ${LabelComponent} {
          ${activeLabel};
        }
      `;
    }
  }}

  ${InputComponent} {
    &:focus,
    &:valid,
    &:invalid {
      &::placeholder {
        opacity: 1;
      }

      & + ${LabelComponent} {
        ${activeLabel};
      }
    }
  }

  ${({ alwaysActive }) =>
    alwaysActive &&
    css`
      ${LabelComponent} {
        ${activeLabel};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      border-bottom-color: #b3b3b3;
      cussor: not-allowed;

      ${InputComponent},
      label, span {
        color: #b3b3b3;
      }
    `}
`;

export const Input = ({
  className,
  disabled,
  error,
  label,
  labelInfo,
  pre,
  showRequired,
  style,
  value,
  onChange,
  type,
  ...props
}) => (
  <Group
    alwaysActive={type === 'phone'}
    className={className}
    disabled={disabled}
    error={error.length}
    pre={pre}
    style={style}
  >
    {type === 'phone' ? (
      <PhoneInputComponent
        country="ng"
        value={value}
        disabled={disabled}
        onChange={onChange}
        autoFormat
        inputProps={props}
        {...props}
      />
    ) : pre ? (
      <PreInputWrapper>
        <StyledPre size="small" color="ash4">
          {pre}
        </StyledPre>
        <InputComponent value={value} disabled={disabled} type={type} onChange={onChange} {...props} />
      </PreInputWrapper>
    ) : (
      <InputComponent value={value} disabled={disabled} type={type} onChange={onChange} {...props} />
    )}

    <Label error={error.length} required={showRequired} subText={labelInfo} text={label} />
    {error && <Error>{error}</Error>}
  </Group>
);

Input.defaultProps = {
  className: '',
  disabled: false,
  error: '',
  labelInfo: '',
  pre: '',
  showRequired: false,
  style: {},
  type: 'text',
};

Input.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelInfo: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  pre: PropTypes.string,
  showRequired: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
};

Label.propTypes = {
  error: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
};
