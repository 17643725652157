import React from 'react';
import PropTypes from 'prop-types';

function SvgBin({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M15.746 5.455v11.818a.886.886 0 01-.273.643.944.944 0 01-.66.266H5.486a.95.95 0 01-.66-.266.886.886 0 01-.273-.643V5.455h11.194zm-.933-1.819v-.909c0-.368-.075-.72-.212-1.043a2.72 2.72 0 00-.608-.886 2.8 2.8 0 00-.908-.592A2.867 2.867 0 0012.015 0H8.283c-.377 0-.74.074-1.07.207-.343.138-.65.341-.908.592-.258.251-.466.551-.608.885a2.663 2.663 0 00-.212 1.043v.91H1.753a.922.922 0 00-.933.908c0 .502.418.91.933.91h.933v11.818c0 .368.076.72.213 1.043.142.335.35.635.607.886a2.8 2.8 0 00.909.592c.33.132.692.206 1.07.206h9.328c.378 0 .74-.074 1.071-.207a2.8 2.8 0 00.91-.592c.257-.251.465-.551.606-.886.137-.321.212-.674.212-1.042V5.455h.933a.922.922 0 00.933-.91.922.922 0 00-.933-.909h-3.732zm-7.463 0v-.909a.886.886 0 01.274-.642.944.944 0 01.66-.267h3.73a.95.95 0 01.66.267.886.886 0 01.273.643v.908H7.35zm0 5.455v5.455c0 .501.418.909.933.909a.921.921 0 00.933-.91V9.091a.922.922 0 00-.933-.91.922.922 0 00-.933.91zm3.732 0v5.455c0 .501.418.909.933.909a.922.922 0 00.933-.91V9.091a.922.922 0 00-.933-.91.922.922 0 00-.933.91z"
        fill={fill}
      />
    </svg>
  );
}

SvgBin.propTypes = {
  fill: PropTypes.string,
};

SvgBin.defaultProps = {
  fill: '#F04A6D',
};

export default SvgBin;
