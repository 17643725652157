import { firebaseAuth, fireStore, timestamp } from 'services/firebase';
import { ANALYTICS } from 'lib/constants/analytics';
import { generateUsername } from '../../lib/utils';

const analyticsCollection = fireStore.collection('analytics');
const storeCollection = fireStore.collection('stores');
const storeVisitsCollection = fireStore.collection('storeVisits');
const userCollection = id => fireStore.collection('users').doc(id);

export const createUser = async (user, callback) => {
  try {
    const { email, id } = user;
    const username = generateUsername(email);

    const data = {
      ...user,
      username,
      createdAt: timestamp,
    };

    const store = { owner: id, createdAt: timestamp, username, visitors: 0 };
    const analytics = {
      [ANALYTICS.ADD_TO_CART]: 0,
      [ANALYTICS.REACHED_CHECKOUT]: 0,
      [ANALYTICS.MADE_PAYMENT]: 0,
    };

    const storeVisits = {
      visitCount: 0,
    };

    await userCollection(id).set(data);
    const { id: storeID } = await storeCollection.add(store);
    await storeCollection.doc(storeID).update({ id: storeID });
    await userCollection(id).update({ store: storeID });
    await analyticsCollection.doc(storeID).set(analytics, { merge: true });
    await storeVisitsCollection.doc(storeID).set(storeVisits, { merge: true });
    store.id = storeID;
    data.store = storeID;

    callback({ user: data, store, analytics, storeVisits }, null);
  } catch (e) {
    callback(null, e);
  }
};

export const getUserById = async (id, callback) => {
  try {
    const userRef = fireStore.collection('users').doc(id);
    const doc = await userRef.get();
    const error = {};
    if (doc.exists) {
      const user = doc.data();
      const query = await storeCollection.where('owner', '==', user.id).get();
      if (query.empty) {
        error.message = 'Store does not exist';
        throw error;
      }
      const store = query.docs[0].data();
      callback({ user, store }, null);
    } else {
      error.message = 'User does not exist';
      throw error;
    }
  } catch (e) {
    callback(null, e);
  }
};

export const login = async (data, callback) => {
  const { email, password } = data;

  try {
    const { user } = await firebaseAuth.signInWithEmailAndPassword(email, password);
    getUserById(user.uid, callback);
  } catch (e) {
    callback(null, e);
  }
};

export const register = async (data, callback) => {
  const { email, password } = data;

  try {
    const { user } = await firebaseAuth.createUserWithEmailAndPassword(email, password);
    await createUser(
      {
        id: user.uid,
        email,
        firstName: '',
        lastName: '',
      },
      callback,
    );

    await firebaseAuth.currentUser.sendEmailVerification();
  } catch (e) {
    callback(null, e);
  }
};

export const resetPassword = async (email, callback) => {
  try {
    await firebaseAuth.sendPasswordResetEmail(email);
    callback('An email has to sent to you. Follow the steps and reset your password.', null);
  } catch (e) {
    callback(null, e);
  }
};

export const logout = async () => {
  await firebaseAuth.signOut();
};

export const updatePassword = async (password, callback) => {
  try {
    await firebaseAuth.currentUser.updatePassword(password);
    callback(true, null);
  } catch (e) {
    callback(false, e);
  }
};
