import * as t from '../types';
import Axios from 'lib/utils/axios';

export const setVisitorDetails = visitorDetails => async dispatch => {
  dispatch({ type: t.SET_VISITOR_DETAILS, visitorDetails });
};

export const setDeliveryDetails = deliveryDetails => async dispatch => {
  dispatch({ type: t.SET_DELIVERY_DETAILS, deliveryDetails });
};

export const setOrderDetails = orderDetails => async dispatch => {
  dispatch({ type: t.SET_ORDER_DETAILS, orderDetails });
};

export const setOrderId = orderId => async dispatch => {
  dispatch({ type: t.SET_ORDER_ID, orderId });
};

export const resetCheckout = (resetData = {}) => async dispatch => {
  dispatch({ type: t.RESET_CHECKOUT, resetData });
};

export const updateVisitorDetails = (data = {}) => {
  return Axios.put('/visitor', data);
};

export const createOrder = (data = {}) => {
  return Axios.post('/order', data);
};

export const uploadProof = (data = {}) => {
  return Axios.put('/order/payment-proof', data);
};
