import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Typography } from 'components/atoms';
import { RequiredIcon } from 'components/icons';
import { Box } from 'rebass/styled-components';

const TextareaComponent = styled.textarea`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearence: none;
  background-color: transparent;
  border-radius: 0;
  border: none;
  color: ${props => props.theme.colors.black};
  display: block;
  font-size: 2rem;
  height: 6rem;
  margin: 0;
  outline: none;
  padding: 0;
  resize: none;
  transition: all 0.2s;
  width: 100%;
`;

const LabelComponent = styled.label`
  bottom: 1rem;
  color: ${props => props.theme.colors.black};
  display: flex;
  font-size: 2rem;
  line-height: 1;
  transition: all 0.2s;
  width: 100%;
`;

const Group = styled(Box)`
  align-items: flex-end;
  display: flex;
  border-bottom: 1px solid ${props => props.theme.colors.black};
  margin-bottom: 3rem;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  position: relative;
  width: 100%;

  ${props => {
    if (props.hasValue) {
      return css`
        ${LabelComponent} {
          margin-bottom: 0.8rem;
          color: ${props.theme.colors.ash};
          font-size: 1.2rem;
          text-transform: uppercase;
        }
      `;
    }
  }}
`;

const Label = ({ error, text, subText, required }) => (
  <LabelComponent>
    <Typography.Text style={{ display: 'inline-flex', alignItems: 'center' }} color={!error ? 'black' : 'error'}>
      {text}
      {required && <RequiredIcon width={5} height={5} style={{ marginLeft: '1rem' }} />}
    </Typography.Text>

    {subText ? (
      <Typography.Text size="tiny" color="black" style={{ marginLeft: 'auto' }}>
        {subText}
      </Typography.Text>
    ) : null}
  </LabelComponent>
);

const Error = styled(Typography.Text)`
  color: ${props => props.theme.colors.error};
  font-size: 13px;
  left: 0;
  position: absolute;
  top: calc(100% + 0.5rem);
`;

export const Textarea = ({ error, label, labelInfo, showRequired, value, style, ...props }) => (
  <Group hasValue={value} style={style} flexDirection="column" error={error.length}>
    <Label error={error.length} text={label} required={showRequired} subText={labelInfo} />
    <TextareaComponent value={value} {...props} />
    {error && <Error>{error}</Error>}
  </Group>
);

Textarea.defaultProps = {
  error: '',
  labelInfo: '',
  showRequired: false,
  style: {},
};

Textarea.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelInfo: PropTypes.string,
  showRequired: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
  value: PropTypes.string.isRequired,
};

Label.propTypes = {
  error: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
};
