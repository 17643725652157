export const getToken = () => {
  return localStorage.getItem('mallblyToken') ? JSON.parse(localStorage.getItem('mallblyToken')).accessToken : '';
};

export const getProfile = () => {
  return localStorage.getItem('mallblyToken') ? JSON.parse(localStorage.getItem('mallblyToken')).user : {};
};

export const truncate = (str, length) => {
  return str.length > length ? str.substring(0, length) + '...' : str;
};
