import React from 'react';
import PropTypes from 'prop-types';

function SvgCreateStore({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 20" fill="none" {...props}>
      <path
        d="M13.1 11.604a3.574 3.574 0 00-5.947 2.674v4.291H3.011v-5.08a.715.715 0 10-1.43 0v5.796a.715.715 0 00.715.715h11.295a.716.716 0 00.715-.715v-5.008a3.563 3.563 0 00-1.205-2.673zm-2.37.528a2.153 2.153 0 012.146 2.146v4.291H8.584v-4.291a2.148 2.148 0 012.146-2.146zm10.638-5.356L17.792.366A.716.716 0 0017.167 0H3.577a.715.715 0 00-.654.424L.063 6.833a.715.715 0 00.087.73c.393.5.878.92 1.43 1.236v1.91a.715.715 0 001.431 0V9.356a4.92 4.92 0 001.045.114 4.99 4.99 0 003.338-1.296 4.942 4.942 0 006.671 0 4.932 4.932 0 004.533 1.147v9.247h-2.146a.715.715 0 100 1.431h2.861a.715.715 0 00.716-.715V8.714c.487-.307.92-.695 1.277-1.147a.715.715 0 00.062-.791zm-6.74-.09a.715.715 0 00-1.13 0 3.508 3.508 0 01-5.537 0 .74.74 0 00-1.13 0 3.594 3.594 0 01-4.24 1.032 3.506 3.506 0 01-1.036-.72L4.04 1.431h12.708l3.123 5.597a3.49 3.49 0 01-2.468 1.012 3.54 3.54 0 01-2.775-1.355z"
        fill={fill}
      />
    </svg>
  );
}

SvgCreateStore.propTypes = {
  fill: PropTypes.string,
};

SvgCreateStore.defaultProps = {
  fill: '#fff',
};

export default SvgCreateStore;
