import React from 'react';
import PropTypes from 'prop-types';

function SvgBack({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M9.4 15.999a.673.673 0 01.163-.413l3.2-3.4c.216-.195.632-.275.874-.05.24.222.232.653-.006.875l-2.25 2.388H22.4a.6.6 0 010 1.2H11.381l2.25 2.388c.205.205.243.65.006.875-.236.224-.667.152-.875-.05l-3.2-3.4a.577.577 0 01-.162-.413z"
        fill={fill}
      />
      <circle cx={16} cy={16} r={15} stroke={fill} />
    </svg>
  );
}

SvgBack.propTypes = {
  fill: PropTypes.string,
};

SvgBack.defaultProps = {
  fill: '#000',
};

export default SvgBack;
