import * as t from '../types';

const INITIAL_STATE = {
  isLoggedIn: false,
  loading: false,
  accessToken: '',
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case t.REGISTER_REQUEST:
      return { ...state, loading: true };

    case t.REGISTER_SUCCESS:
      return { ...state, loading: false, isLoggedIn: true, accessToken: payload.accessToken };

    case t.REGISTER_FAILURE:
      return { ...state, loading: false };

    case t.LOGIN_REQUEST:
      return { ...state, loading: true };

    case t.LOGIN_SUCCESS:
      return { ...state, loading: false, isLoggedIn: true, accessToken: payload.accessToken };

    case t.LOGIN_FAILURE:
      return { ...state, loading: false };

    case t.LOGOUT:
      return { ...state, ...INITIAL_STATE };

    default:
      return { ...state };
  }
};
