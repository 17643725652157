import React, { useCallback, useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import moment from 'moment';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const isAuthenticated = !!localStorage.getItem('mallblyToken');

  const handleAutoLogout = useCallback(() => {
    const authDetails = JSON.parse(localStorage.getItem('mallblyToken'));
    if (authDetails && moment().isSameOrAfter(authDetails.expiresIn)) {
      localStorage.removeItem('mallblyToken');
      window.location.href = `/login?redirect=${window.location.pathname}`;
      return window.location.href;
    }
    return null;
  }, []);

  useEffect(() => {
    handleAutoLogout();
  }, [location.pathname, handleAutoLogout]);

  return (
    <Route
      {...rest}
      render={props => {
        if (!isAuthenticated) {
          return <Redirect to="/login" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};
