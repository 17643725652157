import React from 'react';
import PropTypes from 'prop-types';

function SvgInstagram({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        d="M21.88 0H8.12C3.644 0 0 3.643 0 8.12v13.76C0 26.357 3.643 30 8.12 30h13.76c4.477 0 8.12-3.643 8.12-8.12V8.12C30 3.644 26.357 0 21.88 0zm6.361 21.88a6.369 6.369 0 01-6.362 6.361H8.121a6.37 6.37 0 01-6.362-6.361V8.12A6.37 6.37 0 018.12 1.76h13.758a6.37 6.37 0 016.362 6.362v13.758z"
        fill={fill}
      />
      <path
        d="M15 6.797c-4.523 0-8.203 3.68-8.203 8.203s3.68 8.203 8.203 8.203 8.203-3.68 8.203-8.203S19.523 6.797 15 6.797zm0 14.647A6.452 6.452 0 018.556 15 6.452 6.452 0 0115 8.556 6.452 6.452 0 0121.444 15 6.451 6.451 0 0115 21.444zM23.399 3.884a2.427 2.427 0 00-2.424 2.424A2.427 2.427 0 0023.4 8.732a2.427 2.427 0 002.424-2.424 2.427 2.427 0 00-2.424-2.424zm0 3.089a.666.666 0 11.002-1.332.666.666 0 01-.002 1.332z"
        fill={fill}
      />
    </svg>
  );
}

SvgInstagram.propTypes = {
  fill: PropTypes.string,
};

SvgInstagram.defaultProps = {
  fill: '#000',
};

export default SvgInstagram;
