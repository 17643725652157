import { breakpoints } from './breakpoints';
import { mq } from './media-query';

export const theme = {
  breakpoints: Object.values(breakpoints),
  mq,
  buttons: {
    small: {
      fontSize: '1.2rem',
      fontWeight: 'normal',
      padding: '0.5rem 1.0rem',
    },
    normal: {
      fontSize: '1.4rem',
      fontWeight: 'normal',
      height: '4.0rem',
      padding: '0.5rem 1.0rem',
    },
    large: {
      fontSize: '1.6rem',
      fontWeight: 'bold',
      height: '4.5rem',
      padding: '0.5rem 3.0rem',
    },
  },
  colors: {
    'brown-grey-five': '#aaaaaa',
    'brown-grey-four': '#888888',
    'brown-grey-six': '#5b5b5b',
    ash: '#5B5B5B',
    ash2: '#727272',
    ash3: '#888888',
    ash4: '#AEAEAE',
    ash5: '#999999',
    ash6: '#c0c0c0',
    ash7: '#cccccc',
    ash8: '#9D9D9D',
    ash9: '#555555',
    ash10: '#E5E5E5',
    azure3: '#128bfb',
    black: '#000000',
    crimson: '#F04A6D',
    dark: '#3E3E3E',
    error: '#FF410F',
    green: '#00CD8D',
    greenblue: '#28bb8f',
    lightblue: '#3FA3FF',
    grey: '#F7F7F7',
    grey2: '#F2F2F2',
    ocre: '#d18a00',
    primary: '#FFE500',
    purple: '#6915cf',
    red: '#F40D0D',
    transparent: 'transparent',
    white: '#FFFFFF',
    white3: '#f5f5f5',
  },
  font: {
    align: {
      center: 'center',
      initial: 'initial',
      left: 'left',
      right: 'right',
    },
    size: {
      large: '2.0rem',
      big: '1.8rem',
      normal: '1.6rem',
      small: '1.4rem',
      tiny: '1.2rem',
      inherit: 'inherit',
    },
    weight: {
      '500': 500,
      normal: 'normal',
      medium: 'medium',
      bold: 'bold',
    },
  },
  heading: {
    desktop: {
      h1: '5.0rem',
      h2: '3.0rem',
      h3: '2.5rem',
      h4: '2.0rem',
    },
    mobile: {
      h1: '3.5rem',
      h2: '3.0rem',
      h3: '2.5rem',
      h4: '2.0rem',
    },
  },
};
