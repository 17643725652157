import React from 'react';
import PropTypes from 'prop-types';

function SvgArrowLeft({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 13 22" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.319 10.46a1.076 1.076 0 00-.319.756c0 .27.106.54.319.757l8.56 8.703a1.016 1.016 0 001.488 0 1.057 1.057 0 000-1.514l-7.815-7.946 8.24-8.378a1.057 1.057 0 000-1.514c-.372-.432-1.063-.432-1.489 0L1.32 10.46z"
        fill={fill}
        stroke="inherit"
        strokeWidth={0.5}
      />
    </svg>
  );
}

SvgArrowLeft.propTypes = {
  fill: PropTypes.string,
};

SvgArrowLeft.defaultProps = {
  fill: 'inherit',
};

export default SvgArrowLeft;
