import React from 'react';

function SvgRequired(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 5 5" fill="none" {...props}>
      <circle cx={2.5} cy={2.5} r={2.5} fill="#FF5B5B" />
      <path
        d="M2.15 1v.978l-.59-.34-.26-.15-.3.521.26.15.587.341-.588.337-.259.15.3.522.26-.15.59-.34V4h.6v-.978l.587.337.26.15.3-.522-.26-.15L3.05 2.5l.588-.34.259-.15-.3-.522-.26.15-.587.337V1h-.6z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgRequired;
