import React from 'react';
import PropTypes from 'prop-types';

function SvgAvailable({ fill, ...props }) {
  return (
    <svg width="15" height="8" {...props} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3137 1.53448C10.6294 1.18966 10.6294 0.62069 10.3137 0.258621C9.99798 -0.0862069 9.47707 -0.0862069 9.14558 0.258621L7.04614 2.55172L8.21425 3.82759L10.3137 1.53448Z"
        fill="white"
      />
      <path
        d="M14.7652 0.258621C14.4495 -0.0862069 13.9285 -0.0862069 13.597 0.258621L8.49842 5.82759L5.86228 2.96552C5.54658 2.62069 5.02566 2.62069 4.69417 2.96552C4.36268 3.31034 4.37847 3.87931 4.69417 4.24138L5.10459 4.68966L4.04698 5.84483L1.41084 2.96552C1.09514 2.62069 0.574224 2.62069 0.242734 2.96552C-0.0887559 3.31034 -0.0729707 3.87931 0.242734 4.24138L3.44714 7.74138C3.60499 7.91379 3.82598 8 4.03119 8C4.2364 8 4.45739 7.91379 4.61525 7.74138L6.25691 5.94828L7.89858 7.74138C8.05643 7.91379 8.27742 8 8.48263 8C8.68784 8 8.90883 7.91379 9.06668 7.74138L14.7494 1.53448C15.0809 1.17241 15.0809 0.603448 14.7652 0.258621Z"
        fill={fill}
      />
    </svg>
  );
}

SvgAvailable.propTypes = {
  fill: PropTypes.string,
};

SvgAvailable.defaultProps = {
  fill: 'white',
};

export default SvgAvailable;
