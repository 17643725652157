import React from 'react';
import PropTypes from 'prop-types';

function SvgMark({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 8 6" fill="none" {...props}>
      <path
        d="M1.265 2.58a.75.75 0 00-1.03 1.09l2.25 2.125a.75.75 0 001.107-.085l3.5-4.5A.75.75 0 105.908.29L2.915 4.138 1.265 2.58z"
        fill={fill}
      />
    </svg>
  );
}

SvgMark.propTypes = {
  fill: PropTypes.string,
};

SvgMark.defaultProps = {
  fill: '#fff',
};

export default SvgMark;
