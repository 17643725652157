import * as t from '../types';

const INITIAL_STATE = {
  gettingInvoices: false,
  gettingOrder: false,
  invoices: [],
  loading: false,
  marking: {},
  order: {},
  orders: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.GET_USER_ORDER_REQUEST:
      return { ...state, gettingOrder: true };

    case t.GET_USER_ORDER_SUCCESS:
      return { ...state, gettingOrder: false, order: action.payload };

    case t.GET_USER_ORDER_FAILURE:
      return { ...state, gettingOrder: false };

    case t.GET_USER_ORDERS_REQUEST:
      return { ...state, loading: true };

    case t.GET_USER_ORDERS_SUCCESS:
      return { ...state, loading: false, orders: action.payload };

    case t.GET_USER_ORDERS_FAILURE:
      return { ...state, loading: false };

    case t.GET_USER_INVOICES_REQUEST:
      return { ...state, gettingInvoices: true };

    case t.GET_USER_INVOICES_SUCCESS:
      return { ...state, gettingInvoices: false, invoices: action.payload };

    case t.GET_USER_INVOICES_FAILURE:
      return { ...state, gettingInvoices: false };

    case t.MARK_AS_PAID_REQUEST:
      return { ...state, marking: { ...state.marking, [action.payload]: true } };

    case t.MARK_AS_PAID_SUCCESS:
      return { ...state, marking: { ...state.marking, [action.payload.id]: false }, order: action.payload.data };

    case t.MARK_AS_PAID_FAILURE:
      return { ...state, marking: { ...state.marking, [action.payload]: false } };

    default:
      return { ...state };
  }
};
