export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE';

export const EDIT_PRODUCT_REQUEST = 'EDIT_PRODUCT_REQUEST';
export const EDIT_PRODUCT_SUCCESS = 'EDIT_PRODUCT_SUCCESS';
export const EDIT_PRODUCT_FAILURE = 'EDIT_PRODUCT_FAILURE';

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE';

export const GET_USER_PRODUCTS_REQUEST = 'GET_USER_PRODUCTS_REQUEST';
export const GET_USER_PRODUCTS_SUCCESS = 'GET_USER_PRODUCTS_SUCCESS';
export const GET_USER_PRODUCTS_FAILURE = 'GET_USER_PRODUCTS_FAILURE';

export const FETCH_PRODUCT_PUBLIC_REQUEST = 'FETCH_PRODUCT_PUBLIC_REQUEST';
export const FETCH_PRODUCT_PUBLIC_SUCCESS = 'FETCH_PRODUCT_PUBLIC_SUCCESS';
export const FETCH_PRODUCT_PUBLIC_FAILURE = 'FETCH_PRODUCT_PUBLIC_FAILURE';

export const FETCH_PRODUCT_DETAILS_REQUEST = 'FETCH_PRODUCT_DETAILS_REQUEST';
export const FETCH_PRODUCT_DETAILS_SUCCESS = 'FETCH_PRODUCT_DETAILS_SUCCESS';
export const FETCH_PRODUCT_DETAILS_FAILURE = 'FETCH_PRODUCT_DETAILS_FAILURE';
