import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { RequiredIcon, TickIcon, CaretDownIcon, VoidIcon } from 'components/icons';
import Typography, { Text } from '../Typography';
import { SlideModal as Modal } from 'components/molecules/SlideModal';
import { Searchbar } from './Searchbar';
// import { getWindowDimension } from 'lib/utils';

const activeLabel = css`
  bottom: 4rem;
  color: ${props => props.theme.colors.ash};
  font-size: 1.2rem;
  text-transform: uppercase;

  ${({ theme }) => theme.mq.md`
    bottom: 3.2rem;
  `}
`;

const DivComponent = styled.div`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearence: none;
  background-color: transparent;
  border-radius: 0;
  border: none;
  color: ${props => props.theme.colors.black};
  display: block;
  font-size: 2rem;
  height: 2.5rem;
  line-height: 1;
  margin: 0;
  outline: none;
  padding: 0;
  position: relative;
  transition: all 0.2s;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.mq.md`
    font-size: 2rem;
    height: 2.2rem;
  `}
`;

const LabelComponent = styled.label`
  align-items: center;
  bottom: 1rem;
  color: ${props => props.theme.colors.black};
  display: flex;
  font-size: 2rem;
  left: 0;
  line-height: 1;
  pointer-events: none;
  position: absolute;
  transition: all 0.2s;
  width: 100%;
`;

const Error = styled(Text)`
  color: ${props => props.theme.colors.error};
  font-size: 13px;
  left: 0;
  position: absolute;
  top: calc(100% + 0.5rem);
`;

const Label = ({ error, text, subText, required }) => (
  <LabelComponent>
    <Typography.Text style={{ display: 'inline-flex', alignItems: 'center' }} color={!error ? 'black' : 'error'}>
      {text}
      {required && <RequiredIcon width={5} height={5} style={{ marginLeft: '1rem' }} />}
    </Typography.Text>

    {subText ? (
      <Typography.Text size="tiny" color="black" style={{ marginLeft: 'auto' }}>
        {subText}
      </Typography.Text>
    ) : null}
  </LabelComponent>
);

const Group = styled.div`
  align-items: flex-end;
  border-bottom: 1px solid ${props => props.theme.colors.black};
  display: flex;
  height: ${props => (props.alwaysActive ? '6.5rem' : '6rem')};
  margin-bottom: 3rem;
  padding-bottom: 1rem;
  position: relative;
  width: 100%;
  background-color: ${props => (props.error ? '#fcf2f2' : 'initial')};

  label {
    bottom: ${props => (props.alwaysActive ? '4.5rem' : '4rem')} !important;
  }

  .react-tel-input {
    .form-control {
      background-color: transparent;
      border: none;
      font-size: 2rem;
      height: unset;
      width: 100%;
    }
  }

  input {
    &::placeholder {
      opacity: 0;
    }
  }

  ${props => {
    if (props.dirty) {
      return css`
        background-color: ${props.error ? '#fcf2f2' : props.theme.colors.grey};
        ${LabelComponent} {
          ${activeLabel};
        }
      `;
    }
  }}

  ${({ alwaysActive }) =>
    alwaysActive &&
    css`
      ${LabelComponent} {
        ${activeLabel};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      border-bottom-color: #b3b3b3;
      cussor: not-allowed;

      ${DivComponent},
      label, span {
        color: #b3b3b3;
      }
    `}
`;

const StyledSearchbar = styled(Searchbar)`
  background: #fff;

  & > input {
    &::placeholder {
      color: #999999;
      opacity: 1 !important;
    }
  }
`;

const StyledOptionsList = styled.div`
  width: 100%;
  padding-top: 0.94rem;
  height: 14em;
  overflow: auto;
`;

const OptionsContainer = styled.div`
  background: #fff;
  background: #f5f5f5;

  ${({ normal }) =>
    normal
      ? `
        position: absolute;
        width: 100%;
        top: 110%;
        z-index: 1;
        box-shadow: 0px 10px 10px 7px rgba(0, 0, 0, 0.07);
        
        ${StyledOptionsList} {
          height: 200px;
        }
      `
      : `
        width: 100%;
        height: max-content;
        
        ${StyledOptionsList} {
          height: 40rem;
        }
      `}
`;

const StyledOptionItem = styled.div`
  width: 100%;
  font-size: 1.4rem;
  line-height: 130%;
  padding: 1.071em 1.429em;
  font-weight: normal;
  color: ${props => (props.active ? '#000000' : ' #888888')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  span {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      margin-right: 1rem;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
  }
`;

const OptionItem = ({ active, icon, text, value, onSelect }) => {
  const selectedRef = useRef(null);
  useEffect(() => {
    const selected = selectedRef.current;
    if (selected) {
      selected.scrollIntoView();
    }
  }, []);
  return (
    <StyledOptionItem ref={active ? selectedRef : null} active={active} onClick={e => onSelect(e, value)}>
      <span>
        {icon}
        {text}
      </span>
      {active ? <TickIcon /> : <VoidIcon />}
    </StyledOptionItem>
  );
};

export const OptionsList = ({ options, onSelect, value, onClose, optionComponent: C }) => {
  const [term, setTerm] = useState('');
  const handleSearch = ({ target: { value } }) => {
    setTerm(value.toLowerCase());
  };

  const filteredOptions = options.filter(
    item => item.value.toLowerCase().includes(term) || item.text.toLowerCase().includes(term),
  );

  const handleOptionSelect = (e, value) => {
    e.stopPropagation();
    return onSelect(value);
  };

  const renderOptions = () => (
    <OptionsContainer>
      <StyledSearchbar
        value={term}
        onChange={e => {
          e.stopPropagation();
          handleSearch(e);
        }}
      />
      <StyledOptionsList>
        {filteredOptions.map((item, index) => {
          const { value: ItemValue } = item;
          const active = value === ItemValue;
          return <C key={index} active={active} {...item} onSelect={handleOptionSelect} />;
        })}
      </StyledOptionsList>
    </OptionsContainer>
  );

  return (
    <Modal toggle={onClose} open={true}>
      {renderOptions()}
    </Modal>
  );
};

export const Select = ({
  className,
  disabled,
  error,
  label,
  labelInfo,
  showRequired,
  style,
  value,
  onChange,
  options,
  modalOption,
  placeholder,
  ...props
}) => {
  const [state, setStateOrigin] = useState({ value: '', showOptions: false, modalOption: false });
  const selectRef = useRef(null);
  const setState = useCallback(
    change => {
      const newState = typeof change === 'function' ? { ...state, ...change(state) } : { ...state, ...change };
      return setStateOrigin(newState);
    },
    [state],
  );

  const handleOptionSelect = value => {
    onChange({ target: { name: props.name, value } });
    return setState({ value, showOptions: !state.showOptions });
  };

  const getSelectText = () => {
    const selectedOption = options.find(item => item.value === state.value);
    if (selectedOption) return selectedOption.text;
    return placeholder;
  };

  const handleClickOutside = useCallback(
    event => {
      if (!selectRef.current.contains(event.target)) {
        setState({
          showOptions: false,
        });
      }
    },
    [setState],
  );

  const handleToggle = () => setState(({ showOptions }) => ({ showOptions: !showOptions }));

  useEffect(() => {
    if (value) {
      setState({ value });
    }

    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <Group
      onClick={handleToggle}
      alwaysActive={true}
      className={className}
      disabled={disabled}
      error={error.length}
      style={style}
      ref={selectRef}
    >
      <DivComponent disabled={disabled}>
        {getSelectText()} <CaretDownIcon fill="#000" width="0.7em" />
      </DivComponent>
      <Label error={error.length} required={showRequired} subText={labelInfo} text={label} />
      {error && <Error>{error}</Error>}
      {state.showOptions && (
        <OptionsList
          options={options}
          onSelect={handleOptionSelect}
          onClose={handleToggle}
          value={state.value}
          open={state.showOptions}
          optionComponent={OptionItem}
        />
      )}
    </Group>
  );
};

Select.defaultProps = {
  className: '',
  disabled: false,
  error: '',
  labelInfo: '',
  showRequired: false,
  style: {},
  options: [],
  modalOption: false,
  placeholder: 'Select',
  onChange: () => null,
};

Select.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelInfo: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showRequired: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
  value: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  modalOption: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
};
