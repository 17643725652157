import * as t from '../types';

const INITIAL_STATE = {
  newOrders: [],
  newVisitors: [],
  totalOrders: 0,
  totalPendingOrders: [],
  totalSales: 0,
  loading: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.GET_DASHBOARD_SUMMARY_REQUEST:
      return { ...state, loading: true };

    case t.GET_DASHBOARD_SUMMARY_SUCCESS:
      return { ...state, loading: false, ...action.payload };

    case t.GET_DASHBOARD_SUMMARY_FAILURE:
      return { ...state, loading: false };

    default:
      return { ...state };
  }
};
