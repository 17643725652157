import * as t from '../types';

export const setMessage = (store, message, index) => async dispatch => {
  dispatch({ type: t.SET_MESSAGE, store, message, index });
};

export const setChatCompleted = (completed = false) => async dispatch => {
  dispatch({ type: t.SET_CHAT_COMPLETED, completed });
};

export const replaceLastMessage = (store, message) => async dispatch => {
  dispatch({ type: t.REPLACE_LAST_MESSAGE, store, message });
};

export const resetChat = store => async dispatch => {
  dispatch({ type: t.RESET_CHAT, store });
};
