import React from 'react';
import PropTypes from 'prop-types';

function SvgFacebook({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        d="M15 0C6.729 0 0 6.729 0 15s6.729 15 15 15 15-6.729 15-15S23.271 0 15 0zm0 28.284c-7.325 0-13.284-5.96-13.284-13.284C1.716 7.675 7.676 1.716 15 1.716c7.325 0 13.284 5.96 13.284 13.284 0 7.325-5.96 13.284-13.284 13.284z"
        fill={fill}
      />
      <path
        d="M18.474 6.86H16.44a3.023 3.023 0 00-3.02 3.02v2.288h-1.894a.858.858 0 100 1.716h1.895v7.945a.858.858 0 101.716 0v-7.945h2.932a.858.858 0 000-1.716h-2.932V9.879c0-.718.585-1.303 1.303-1.303h2.034a.858.858 0 000-1.716z"
        fill={fill}
      />
    </svg>
  );
}

SvgFacebook.propTypes = {
  fill: PropTypes.string,
};

SvgFacebook.defaultProps = {
  fill: '#000',
};

export default SvgFacebook;
