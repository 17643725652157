import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass/styled-components';
import { Typography } from 'components/atoms';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isObjEmpty } from 'lib/utils';
import { MallblyIcon } from 'components/icons';

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  padding: 1rem 2rem;
  height: 6rem;
  width: 100%;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  z-index: 1;
`;

const AuthWrapper = styled(Box)`
  background-color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 6rem;
  position: relative;
`;

const AuthBody = styled(Box)`
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 5rem;
  }
`;

const Header = styled(Box)`
  display: flex;
  margin-bottom: 4rem;
`;

const Title = styled(Typography.Heading)`
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

const Subtitle = styled(Typography.Paragraph)``;

const OtherWrapper = styled(Box)`
  align-items: center;
  background-color: ${props => props.theme.colors.grey};
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin: 0 2rem 2rem 2rem;
`;

const OtherTitle = styled(Typography.Text)`
  margin-right: 1rem;
`;

const OtherLink = styled(Typography.Text)`
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const AuthLayout = ({ children, subtitle, title, other }) => {
  return (
    <AuthWrapper flexDirection="column">
      <Nav>
        <Link to="/">
          <MallblyIcon width="9.7rem" height="3.5rem" />
        </Link>
      </Nav>
      <Box display="flex" flexDirection="column" flex="1" py="3rem">
        <Flex justifyContent="center" px={20}>
          <Box width={[1, 370]} flexDirection="column">
            <Header flexDirection="column">
              <Title type="h2">{title}</Title>
              <Subtitle fontSize="small" textAlign="center" color="ash">
                {subtitle}
              </Subtitle>
            </Header>
            <AuthBody>{children}</AuthBody>
          </Box>
        </Flex>
      </Box>
      {!isObjEmpty(other) && (
        <OtherWrapper>
          <OtherTitle>{other.title}</OtherTitle>
          <OtherLink as={Link} to={other.link}>
            {other.text}
          </OtherLink>
        </OtherWrapper>
      )}
    </AuthWrapper>
  );
};

export default AuthLayout;

AuthLayout.defaultProps = {
  other: {},
};

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
  other: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string,
  }),
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
