import React from 'react';
import PropTypes from 'prop-types';

function SvgEye({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 15 9" fill="none" {...props}>
      <path
        d="M7.5 2.32c-.237 0-.458.048-.663.111.331.174.568.521.568.916 0 .568-.458 1.026-1.026 1.026-.395 0-.742-.237-.916-.568-.063.205-.11.426-.11.663 0 1.2.963 2.163 2.162 2.163 1.2 0 2.163-.963 2.163-2.163A2.187 2.187 0 007.5 2.32z"
        fill={fill}
      />
      <path
        d="M14.762 2.542C14.636 2.432 11.462 0 7.5 0 3.537 0 .38 2.447.238 2.542a.645.645 0 00-.11.884.635.635 0 00.489.237.73.73 0 00.394-.127c.016-.015 1.232-.947 3.016-1.61A4.268 4.268 0 003.174 4.5 4.322 4.322 0 007.5 8.825 4.322 4.322 0 0011.826 4.5c0-.963-.316-1.847-.853-2.557 1.784.663 3 1.594 3.016 1.61.268.221.663.158.884-.11a.663.663 0 00-.11-.9zM7.5 7.704A3.207 3.207 0 014.295 4.5 3.207 3.207 0 017.5 1.295a3.207 3.207 0 013.205 3.204A3.207 3.207 0 017.5 7.704z"
        fill={fill}
      />
    </svg>
  );
}

SvgEye.propTypes = {
  fill: PropTypes.string,
};

SvgEye.defaultProps = {
  fill: '#000',
};

export default SvgEye;
