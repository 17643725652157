import React from 'react';
import PropTypes from 'prop-types';

function SvgTrash({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 15" fill="none" {...props}>
      <path
        d="M11.2 4.09v8.864a.664.664 0 01-.205.483.707.707 0 01-.495.2h-7a.713.713 0 01-.495-.2.664.664 0 01-.205-.482V4.09h8.4zm-.7-1.363v-.682c0-.276-.057-.54-.16-.782a2.04 2.04 0 00-.455-.664 2.101 2.101 0 00-.682-.444C8.955.055 8.683 0 8.4 0H5.6a2.106 2.106 0 00-1.485.6c-.193.188-.349.412-.455.663-.103.242-.16.506-.16.782v.682H.7c-.386 0-.7.306-.7.682 0 .376.314.682.7.682h.7v8.863c0 .277.057.541.16.783.106.251.262.476.455.664.193.188.424.34.682.444.248.1.52.155.803.155h7a2.144 2.144 0 001.485-.6 2.002 2.002 0 00.615-1.446V4.091h.7c.386 0 .7-.306.7-.682a.691.691 0 00-.7-.682h-2.8zm-5.6 0v-.682a.664.664 0 01.205-.482.708.708 0 01.495-.2h2.8a.714.714 0 01.495.2.664.664 0 01.205.482v.682H4.9zm0 4.091v4.091c0 .377.314.682.7.682.386 0 .7-.305.7-.682V6.82a.691.691 0 00-.7-.683c-.386 0-.7.306-.7.682zm2.8 0v4.091c0 .377.314.682.7.682.386 0 .7-.305.7-.682V6.82a.691.691 0 00-.7-.683c-.386 0-.7.306-.7.682z"
        fill={fill}
      />
    </svg>
  );
}

SvgTrash.propTypes = {
  fill: PropTypes.string,
};

SvgTrash.defaultProps = {
  fill: '#F04A6D',
};

export default SvgTrash;
