import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import { Text } from './Typography';

const ItemWrapper = styled(Box)`
  align-items: center;
  border-bottom: ${({ borderWidth, inactiveTabBorderColor, theme }) =>
    `${borderWidth} solid ${theme.colors[inactiveTabBorderColor]}`};
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-bottom: -${({ borderWidth }) => borderWidth};
  padding: 1.5rem;
  text-decoration: none;

  ${Text} {
    color: ${({ theme, inactiveTabColor }) => theme.colors[inactiveTabColor]};
    font-size: ${({ theme }) => theme.font.size.small};
    font-weight: ${({ theme }) => theme.font.weight.normal};
    text-decoration: none;
    user-select: none;
  }

  ${({ active, theme, activeTabColor, borderWidth, activeTabBorderColor }) =>
    active &&
    css`
      border-bottom: ${borderWidth} solid ${theme.colors[activeTabBorderColor]};
      ${Text} {
        color: ${theme.colors[activeTabColor]};
        font-weight: 500;
      }
    `}

  &.active {
    border-bottom: ${({ theme, activeTabBorderColor, borderWidth }) =>
      `${borderWidth} solid ${theme.colors[activeTabBorderColor]}`};
    ${Text} {
      color: ${({ theme, activeTabColor }) => theme.colors[activeTabColor]};
      font-weight: 500;
    }
  }
`;

const TabsWrapper = styled(Box)`
  align-items: center;
  border-bottom: ${({ theme, inactiveTabBorderColor, borderWidth }) =>
    `${borderWidth} solid ${theme.colors[inactiveTabBorderColor]}`};
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Item = ({
  active,
  activeTabBorderColor,
  activeTabColor,
  borderWidth,
  children,
  inactiveTabBorderColor,
  inactiveTabColor,
  ...props
}) => {
  return (
    <ItemWrapper
      active={active}
      activeTabBorderColor={activeTabBorderColor}
      activeTabColor={activeTabColor}
      borderWidth={borderWidth}
      inactiveTabBorderColor={inactiveTabBorderColor}
      inactiveTabColor={inactiveTabColor}
      {...props}
    >
      <Text>{children}</Text>
    </ItemWrapper>
  );
};

const Tabs = ({ borderWidth, children, inactiveTabBorderColor, ...props }) => (
  <TabsWrapper inactiveTabBorderColor={inactiveTabBorderColor} borderWidth={borderWidth} {...props}>
    {children}
  </TabsWrapper>
);

Tabs.propTypes = {
  borderWidth: PropTypes.string,
  inactiveTabBorderColor: PropTypes.string,
};

Tabs.defaultProps = {
  borderWidth: '2px',
  inactiveTabBorderColor: 'white',
};

Item.propTypes = {
  active: PropTypes.bool,
  activeTabBorderColor: PropTypes.string,
  activeTabColor: PropTypes.string,
  borderWidth: PropTypes.string,
  inactiveTabBorderColor: PropTypes.string,
  inactiveTabColor: PropTypes.string,
};

Item.defaultProps = {
  active: false,
  activeTabBorderColor: 'black',
  activeTabColor: 'black',
  borderWidth: '2px',
  inactiveTabBorderColor: 'white',
  inactiveTabColor: 'ash5',
};

Tabs.Item = Item;

export { Tabs };
