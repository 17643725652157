import React from 'react';
import PropTypes from 'prop-types';

function SvgSearch({ fill, ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 15 15" fill="none" {...props}>
      <path
        d="M0 6.34a6.322 6.322 0 003.55 5.672 6.338 6.338 0 006.66-.682l3.484 3.48a.792.792 0 001.116-1.115l-3.483-3.48A6.323 6.323 0 008.384.341a6.338 6.338 0 00-7.687 3.1A6.322 6.322 0 000 6.341zm6.332-4.744a4.752 4.752 0 014.388 2.929 4.74 4.74 0 01-3.461 6.469 4.753 4.753 0 01-4.876-2.018 4.742 4.742 0 01.591-5.99 4.751 4.751 0 013.358-1.39z"
        fill={fill}
      />
    </svg>
  );
}

SvgSearch.propTypes = {
  fill: PropTypes.string,
};

SvgSearch.defaultProps = {
  fill: '#999',
};

export default SvgSearch;
