import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';
import GlobalStyle from 'GlobalStyle';
import FullStory from 'react-fullstory';
import { ToastContainer } from 'react-toastify';
import App from 'App';
import { theme } from './theme';

const ORG_ID = process.env.REACT_APP_FULLSTORY;

const Root = ({ store, persistor }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <FullStory org={ORG_ID} />
          <ToastContainer />
          <GlobalStyle />
          <Route path="/" component={App} />
        </ThemeProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

export default Root;
