import React, { useState, useCallback } from 'react';
import AuthLayout from 'components/organisms/AuthLayout';
import { Button, Form, Typography } from 'components/atoms';
import { Box } from 'rebass';
import { toastr } from 'lib/toast';
import { useDispatch } from 'react-redux';
import { resetPasswordAction } from 'store/actions';
import { useParams } from 'react-router-dom';

const INITIAL_FORM = {
  password: '',
  confirmPassword: '',
};

const ResetPassword = props => {
  const [form, setForm] = useState({ ...INITIAL_FORM });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ ...INITIAL_FORM });
  const [error, setError] = useState('');
  const { token } = useParams();
  const dispatch = useDispatch();

  const resetPassword = useCallback(
    (data, callback) => {
      return dispatch(resetPasswordAction(data, callback));
    },
    [dispatch],
  );

  const onTextChange = e => {
    const { name, value } = e.target;
    setForm(v => ({ ...v, [name]: value }));
  };

  const validateForm = () => {
    setError('');
    const newErrors = { ...INITIAL_FORM };
    const { password, confirmPassword } = form;

    if (!password) {
      newErrors.password = 'Please enter a password';
    } else if (password.length < 8) {
      newErrors.password = 'Password length should be more than 6 characters';
    }
    if (!confirmPassword) {
      newErrors.confirmPassword = 'Please enter your password again for confirmation';
    } else if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Password does not match';
    }

    setErrors(newErrors);
    const invalid = Object.values(newErrors).some(value => value);
    return invalid;
  };

  const onSubmit = async event => {
    event.preventDefault();
    const { history } = props;
    if (!validateForm()) {
      setLoading(true);
      resetPassword({ newPassword: form.password, resetToken: token }, (success, e) => {
        if (success) {
          toastr(success);
          history.push('/login');
        } else {
          const { code, message } = e;
          let errorToShow;
          if (code === 'auth/user-not-found') {
            errorToShow = "User not found. Don't have an mallbly account? Sign up here.";
          } else if (code === 'auth/wrong-password') {
            errorToShow = 'The password is invalid. Please enter the correct password or reset your password.';
          } else if (code === 'auth/too-many-requests') {
            errorToShow = 'Too many login attempts. Please try again in a few minutes';
          } else {
            errorToShow = message;
          }
          setError(errorToShow);
        }
        setLoading(false);
      });
    }
  };

  return (
    <AuthLayout
      title="Reset Password"
      subtitle="Enter a strong password to continue"
      other={{ title: 'Has token expired?', text: 'Resend token', link: '/forgot-password' }}
    >
      <Form onSubmit={onSubmit}>
        <Form.Input
          onChange={onTextChange}
          error={errors.password}
          value={form.password}
          name="password"
          type="password"
          label="Enter new password"
        />
        <Form.Input
          onChange={onTextChange}
          error={errors.confirmPassword}
          value={form.confirmPassword}
          name="confirmPassword"
          type="password"
          label="Confirm new password"
        />

        {error && (
          <Typography.Paragraph fontSize="tiny" color="error" style={{ marginBottom: '2rem' }}>
            {error}
          </Typography.Paragraph>
        )}
      </Form>
      <Box>
        <Button
          loading={loading.toString()}
          onClick={onSubmit}
          rounded
          block
          size="large"
          fontWeight="bold"
          fontSize="big"
        >
          Continue
        </Button>
      </Box>
    </AuthLayout>
  );
};

export default ResetPassword;
