export const GET_STORE_REQUEST = 'GET_STORE_REQUEST';
export const GET_STORE_SUCCESS = 'GET_STORE_SUCCESS';
export const GET_STORE_FAILURE = 'GET_STORE_FAILURE';

export const GET_STORE_PRODUCT_REQUEST = 'GET_STORE_PRODUCT_REQUEST';
export const GET_STORE_PRODUCT_SUCCESS = 'GET_STORE_PRODUCT_SUCCESS';
export const GET_STORE_PRODUCT_FAILURE = 'GET_STORE_PRODUCT_FAILURE';

export const ADD_PAYMENT_METHOD_REQUEST = 'ADD_PAYMENT_METHOD_REQUEST';
export const ADD_PAYMENT_METHOD_SUCCESS = 'ADD_PAYMENT_METHOD_SUCCESS';
export const ADD_PAYMENT_METHOD_FAILURE = 'ADD_PAYMENT_METHOD_FAILURE';

export const FETCH_STORE_SUCCESS = 'FETCH_STORE_SUCCESS';
export const CREATE_STORE_REQUEST = 'CREATE_STORE_REQUEST';
export const CREATE_STORE_SUCCESS = 'CREATE_STORE_SUCCESS';
export const CREATE_STORE_FAILURE = 'CREATE_STORE_FAILURE';

export const UPDATE_STORE_FAILURE = 'UPDATE_STORE_FAILURE';
export const UPDATE_STORE_SUCCESS = 'UPDATE_STORE_SUCCESS';
export const UPDATE_STORE_REQUEST = 'UPDATE_STORE_REQUEST';

export const ADD_DELIVERY_REQUEST = 'ADD_DELIVERY_REQUEST';
export const ADD_DELIVERY_SUCCESS = 'ADD_DELIVERY_SUCCESS';
export const ADD_DELIVERY_FAILURE = 'ADD_DELIVERY_FAILURE';

export const UPDATE_STORE_IMAGE_REQUEST = 'UPDATE_STORE_IMAGE_REQUEST';
export const UPDATE_STORE_IMAGE_SUCCESS = 'UPDATE_STORE_IMAGE_SUCCESS';
export const UPDATE_STORE_IMAGE_FAILURE = 'UPDATE_STORE_IMAGE_FAILURE';

export const FETCH_STORE_PUBLIC_REQUEST = 'FETCH_STORE_PUBLIC_REQUEST';
export const FETCH_STORE_PUBLIC_SUCCESS = 'FETCH_STORE_PUBLIC_SUCCESS';
export const FETCH_STORE_PUBLIC_FAILURE = 'FETCH_STORE_PUBLIC_FAILURE';
