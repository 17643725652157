import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Box } from 'rebass/styled-components';
import { Button } from './Button';
import { Text } from './Typography';

const FabButton = styled(Button)`
  cursor: pointer;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.25);
  display: inline-flex;
  height: 5rem;
  justify-content: center;
  width: 5rem;
`;

const Svg = styled.svg`
  height: 2rem;
  pointer-events: none;
  width: 2.1rem;

  ${props =>
    !!props.iconColor &&
    css`
      path {
        fill: ${props => props.theme.colors[props.iconcolor] || props.theme.colors.black};
        stroke: ${props => props.theme.colors[props.iconcolor] || props.theme.colors.black};
      }
    `}
`;

const ItemText = styled(Text)`
  color: ${({ theme }) => theme.colors.ash7};
  font-size: ${({ theme }) => theme.font.size.small};
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 100%;
  transform: translateX(100%);
  transition: all 0.2s cubic-bezier(0.13, -0.3, 0.88, 1.26);
  white-space: nowrap;
`;

const ItemWrapper = styled(Box)`
  align-items: center;
  display: flex;
  position: relative;

  &:hover {
    ${ItemText} {
      opacity: 1;
      transform: translateX(-10px);
    }
  }
`;

const Wrapper = styled.div`
  bottom: 5rem;
  position: fixed;
  right: 1rem;

  & > ${ItemWrapper} {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
`;

const FabItem = ({ icon, text, iconColor, theme, ...props }) => {
  return (
    <ItemWrapper>
      {text && <ItemText>{text}</ItemText>}
      <FabButton {...props}>
        <Svg as={icon} iconcolor={iconColor} />
      </FabButton>
    </ItemWrapper>
  );
};

const Fab = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

FabItem.propTypes = {
  icon: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

Fab.Item = FabItem;

export { Fab };
