import clean from 'lodash-clean';
import * as t from '../types';
import Axios from 'lib/utils/axios';
import { toastr } from 'lib/toast/index';

export const getUserByIdAction = (id, callback = () => {}) => async dispatch => {
  return new Promise((resolve, reject) => {});
};

export const fetchUserAction = () => async dispatch => {
  try {
    dispatch({ type: t.FETCH_USER_REQUEST });
    const response = await Axios.get('/user');
    dispatch({ type: t.FETCH_USER_SUCCESS, payload: response.data.data.user });
    return true;
  } catch (error) {
    dispatch({ type: t.FETCH_USER_FAILURE });
  }
};
export const updateUserAction = options => async dispatch => {
  try {
    dispatch({ type: t.UPDATE_USER_REQUEST });
    const response = await Axios.put('/user', clean(options));
    dispatch({ type: t.UPDATE_USER_SUCCESS, payload: response.data.data.user });
    toastr('Profile Info Updated', 'success');
    return true;
  } catch (error) {
    if (error.response && error.response.status === 409) {
      toastr('Could not update password. Ensure the old password you entered is correct', 'error');
    }
    dispatch({ type: t.UPDATE_USER_FAILURE });
  }
};
