import styled from 'styled-components';
import { Box } from 'rebass/styled-components';

export const BottomBar = styled(Box)`
  background-color: ${({ theme }) => theme.colors.white};
  bottom: 0;
  box-shadow: 0 -10px 10px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  min-height: 6.5rem;
  right: 0;
  padding: 1rem 2rem;
  position: fixed;
  width: 100%;

  ${({ theme }) => theme.mq.md`
    max-width: 36rem;
  `}
`;
