import styled from 'styled-components';
import { Typography } from 'components/atoms';
import { Box } from 'rebass/styled-components';
import { animated } from 'react-spring';

export const Overlay = styled(animated.div)`
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 3;

  ${({ theme }) => theme.mq.md`
  max-width: 36rem;
`}
`;

export const Wrapper = styled(animated.div)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1rem 1rem 0 0;
  bottom: 0;
  box-shadow: 0 -10px 10px 0 rgba(0, 0, 0, 0.05);
  right: 0;
  position: fixed;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;

  ${({ theme }) => theme.mq.md`
  max-width: 36rem;
`}
`;

export const HandlerWrapper = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
  padding-top: 1.5rem;
`;

export const Handler = styled.div`
  background-color: #e5e5e5;
  border-radius: 5rem;
  cursor: grab;
  height: 0.5rem;
  width: 4rem;
`;

export const TextWrapper = styled.div`
  bottom: calc(100% + 2rem);
  padding: 0 1rem;
  position: absolute;
  width: 100%;
`;

export const Title = styled(Typography.Paragraph)`
  color: white;
  font-size: ${({ theme }) => theme.font.size.small};
  text-align: center;
`;
