import React, { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { RequiredIcon } from 'components/icons';
import Typography, { Text } from '../Typography';

const selectRef = createRef();

const activeLabel = css`
  bottom: 4rem;
  color: ${props => props.theme.colors.ash};
  font-size: 1.2rem;
  text-transform: uppercase;

  ${({ theme }) => theme.mq.md`
    bottom: 3.2rem;
  `}
`;

const DivComponent = styled.div`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearence: none;
  background-color: transparent;
  border-radius: 0;
  border: none;
  color: ${props => props.theme.colors.black};
  display: block;
  font-size: 2rem;
  line-height: 1;
  margin: 0;
  outline: none;
  padding: 0;
  position: relative;
  transition: all 0.2s;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* overflow: auto; */

  position: relative;

  ${({ theme }) => theme.mq.md`
    font-size: 2rem;
  `}
`;

const LabelComponent = styled.label`
  align-items: center;
  color: ${props => props.theme.colors.black};
  && {
    display: flex;
  }
  font-size: 2rem;
  justify-content: space-between;
  line-height: 1;
  pointer-events: none;
  transition: all 0.2s;
  width: 100%;
`;

const Error = styled(Text)`
  color: ${props => props.theme.colors.error};
  font-size: 13px;
  left: 0;
  position: absolute;
  top: calc(100% + 0.5rem);
`;

const Label = ({ error, text, subText, required, buttonText }) => (
  <LabelComponent>
    <Typography.Text style={{ display: 'inline-flex', alignItems: 'center' }} color={!error ? 'black' : 'error'}>
      {text}
      {required && <RequiredIcon width={5} height={5} style={{ marginLeft: '1rem' }} />}
    </Typography.Text>

    {subText ? (
      <Typography.Text size="tiny" color="black" style={{ marginLeft: 'auto' }}>
        {subText}
      </Typography.Text>
    ) : null}
  </LabelComponent>
);

const Group = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  min-height: ${props => (props.alwaysActive ? '6.5rem' : '6rem')};
  margin-bottom: 3rem;
  padding-bottom: 1rem;
  position: relative;
  width: 100%;
  background-color: ${props => (props.error ? '#fcf2f2' : 'initial')};

   & > * {
    display: block;;
  }

  label {
    bottom: ${props => (props.alwaysActive ? '4.5rem' : '4rem')} !important;
  }

  .react-tel-input {
    .form-control {
      background-color: transparent;
      border: none;
      font-size: 2rem;
      height: unset;
      width: 100%;
    }
  }

  input {
    &::placeholder {
      opacity: 0;
    }
  }

  ${props => {
    if (props.dirty) {
      return css`
        background-color: ${props.error ? '#fcf2f2' : props.theme.colors.grey};
        ${LabelComponent} {
          ${activeLabel};
        }
      `;
    }
  }}

  ${({ alwaysActive }) =>
    alwaysActive &&
    css`
      ${LabelComponent} {
        ${activeLabel};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      border-bottom-color: #b3b3b3;
      cursor: not-allowed;

      ${DivComponent},
      label, span {
        color: #b3b3b3;
      }
    `}
`;

export class AddMultiple extends Component {
  state = {
    state: '',
    showOptions: false,
  };

  componentDidMount() {
    this.makeSubscription();
  }

  componentWillUnmount() {
    this.removeSubscription();
  }
  componentDidUpdate(prevState, prevProps) {}
  makeSubscription = () => {
    document.addEventListener('mousedown', this.handleClickOutside);
  };

  removeSubscription = () => {
    document.removeEventListener('mousedown', this.handleClickOutside);
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleOptionSelect = value => {
    this.handleChange({ target: { name: this.props.name, value } });
    return this.setState({ value, showOptions: !this.state.showOptions });
  };

  handleClickOutside = event => {
    if (!selectRef.current.contains(event.target)) {
      this.setState({
        showOptions: false,
      });
    }
  };

  handleToggle = () => {
    this.setState(({ showOptions }) => ({ showOptions: !showOptions }));
  };

  handleShowOptions = e => {
    e.preventDefault();
    this.setState(() => ({ showOptions: true }));
  };

  render() {
    const {
      className,
      disabled,
      error,
      label,
      labelInfo,
      showRequired,
      style,
      actionComponent,
      children,
      selectedOptionList,
    } = this.props;
    return (
      <Group
        alwaysActive={true}
        className={className}
        disabled={disabled}
        error={error.length}
        style={{ ...style }}
        ref={selectRef}
      >
        <Label error={error.length} required={showRequired} subText={labelInfo} text={label} />
        {actionComponent}
        <DivComponent>{selectedOptionList}</DivComponent>
        {children}
        {error && <Error>{error}</Error>}
      </Group>
    );
  }
}

AddMultiple.defaultProps = {
  className: '',
  disabled: false,
  error: '',
  labelInfo: '',
  showRequired: false,
  style: {},
  placeholder: 'Select',
};

AddMultiple.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelInfo: PropTypes.string,
  showRequired: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
  placeholder: PropTypes.string.isRequired,
};
